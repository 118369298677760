<template>
  <div
    class="image-upload"
    :class="{ 'has-image': imageUrl !== '' || image !== '' || uploading }"
  >
    <a-upload-dragger
      name="file"
      accept="image/*"
      :multiple="false"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :customRequest="uploadAWS"
      :disabled="disableUpload"
    >
      <span
        class="image-upload__info"
        v-if="imageUrl === '' && image === '' && !uploading"
        >Click or drag image to this area to upload</span
      >
      <div
        class="image-upload__loader flex items-center justify-center flex-col"
        v-if="uploading"
      >
        <i class="circle-loader"></i>
        <span>Uploading...</span>
      </div>
      <img class="image-upload__img" :src="imageUrl || image" />
    </a-upload-dragger>
  </div>
</template>

<script>
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

import {
  S3Client,
  ListObjectsCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "ImageUpload",
  components: {},
  props: {
    unEncryptSurveyId: {
      type: Number,
    },
    image: {
      type: String,
      default() {
        return "";
      },
    },
    disableUpload: {
      type: Boolean,
    },
  },
  data() {
    return {
      allowUploading: true,
      imageUrl: "",
      uploading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    beforeUpload(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          if (width < 800 && height < 800) {
            this.imageValidation(
              "The width and height of the image must be greater than 800 px!"
            );
          }
        });
      });

      const isLt50KB = file.size / 1024 < 50;
      if (isLt50KB) {
        this.imageValidation("An image size must be at least 50KB!");
      }
    },
    imageValidation(text) {
      this.$notification["warning"]({
        message: `Oops`,
        description: text,
        placement: "bottomLeft",
        duration: 5,
      });
      this.allowUploading = false;
    },
    uploadAWS(info) {
      setTimeout(() => {
        if (!this.allowUploading) {
          this.allowUploading = true;
          return;
        }
        this.uploading = true;
        const file = info.file;
        const assetID = uuidv4();

        // create s3 client
        const REGION = "us-east-1"; //REGION
        const s3 = new S3Client({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId: "us-east-1:a8e31567-50e5-428c-8614-84f132d4ec6f", // IDENTITY_POOL_ID
          }),
        });
        const bucketName = "hotspexstudio"; //BUCKET_NAME

        // create albumn/floder
        const albumName = "s" + this.unEncryptSurveyId;
        let albumPhotosKey = albumName + "/PackProducts" + "/CONTROL";
        const uploadFile = () => {
          console.info("bucket existed, we can upload to bucket directly");

          try {
            let fileName = assetID + "." + file.type.replace(/(.*)\//g, "");
            const photoKey = albumPhotosKey + fileName;
            const uploadParams = {
              Bucket: bucketName,
              Key: photoKey,
              Body: file,
            };

            try {
              s3.send(new PutObjectCommand(uploadParams)).then(
                () => {
                  this.imageUrl = `https://${bucketName}.s3.${REGION}.amazonaws.com/${albumPhotosKey}${fileName}`;
                  this.uploading = false;
                  this.$emit("image:url", this.imageUrl);
                },
                (error) => {
                  // error handling.
                  console.log("upload error", error);
                }
              );
            } catch (err) {
              return alert(
                "There was an error uploading your photo: ",
                err.message
              );
            }
          } catch (err) {
            if (!file) {
              return alert("Choose a file to upload first.");
            }
          }
        };

        const createFolder = () => {
          try {
            const params = { Bucket: bucketName, Key: albumPhotosKey };
            s3.send(new PutObjectCommand(params)).then(() => {
              uploadFile();
            });
          } catch (err) {
            return alert(
              "There was an error creating your album: " + err.message
            );
          }
        };

        const checkAlumb = () => {
          s3.send(
            new ListObjectsCommand({
              Prefix: albumPhotosKey,
              Bucket: bucketName,
            })
          ).then((data) => {
            if (data.Contents === undefined) {
              createFolder();
            } else {
              uploadFile();
            }
          });
        };
        checkAlumb();
      }, 1000);
    },
  },
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.image-upload {
  min-width: 143px;
  max-width: 143px;
  height: 180px;
  position: relative;
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 100%;
    height: 120px;
  }
  &:after {
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    content: "";
    width: 41px;
    height: 41px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 312.602 312.602' style='enable-background:new 0 0 312.602 312.602' xml:space='preserve'%3E%3Cpath style='fill:%23000002' d='M251.52 137.244c-3.966 0-7.889.38-11.738 1.134-1.756-47.268-40.758-85.181-88.448-85.181-43.856 0-80.964 32.449-87.474 75.106C28.501 129.167 0 158.201 0 193.764c0 36.106 29.374 65.48 65.48 65.48h54.782c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H65.48c-27.835 0-50.48-22.645-50.48-50.48 0-27.835 22.646-50.48 50.48-50.48 1.367 0 2.813.067 4.419.206l7.6.658.529-7.61c2.661-38.322 34.861-68.341 73.306-68.341 40.533 0 73.51 32.977 73.51 73.51 0 1.863-.089 3.855-.272 6.088l-.983 11.968 11.186-4.367a45.863 45.863 0 0 1 16.747-3.151c25.409 0 46.081 20.672 46.081 46.081 0 25.408-20.672 46.08-46.081 46.08-.668 0-20.608-.04-40.467-.08-19.714-.04-39.347-.08-39.999-.08-4.668 0-7.108-2.248-7.254-6.681v-80.959l8.139 9.667a7.497 7.497 0 0 0 10.567.907 7.498 7.498 0 0 0 .907-10.567l-18.037-21.427c-2.272-2.699-5.537-4.247-8.958-4.247-3.421 0-6.686 1.548-8.957 4.247l-18.037 21.427a7.498 7.498 0 0 0 .907 10.567 7.466 7.466 0 0 0 4.826 1.763 7.482 7.482 0 0 0 5.741-2.67l7.901-9.386v80.751c0 8.686 5.927 21.607 22.254 21.607.652 0 20.27.04 39.968.079 19.874.041 39.829.081 40.498.081 33.681 0 61.081-27.4 61.081-61.08 0-33.681-27.401-61.081-61.082-61.081z'/%3E%3C/svg%3E")
      center center no-repeat;
    z-index: 1;
    pointer-events: none;
  }
  &.has-image {
    &:after {
      display: none;
    }
  }
  &__info {
    display: block;
    max-width: 100px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    color: #3d3d3d;
    line-height: 15px;
    margin-top: 38px;
    pointer-events: none;
  }
  &__img {
    max-width: 90%;
    max-height: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__loader {
    font-size: 12px;
    position: relative;
    z-index: 1;
    .circle-loader {
      margin: 0 0 5px;
    }
    span {
      background: #fafafa;
      padding: 0 4px;
      border-radius: 3px;
    }
  }
}
</style>
