<template>
  <div
    class="product-item"
    :class="{ 'product-item--control': productInfo.FriendlyName === 'Control' }"
  >
    <div class="product-item__inner">
      <div class="product-item__content">
        <div class="product-item__image">
          <ImageUpload
            :unEncryptSurveyId="unEncryptSurveyId"
            :image="productInfo.ImageURL"
            @image:url="setImageUrl"
          ></ImageUpload>
        </div>
        <div class="product-item__form">
          <a-row :gutter="16">
            <a-col :xs="{ span: 24 }" class="product-item__form-item"
              ><label>Price*</label>
              <div class="position-r flex items-center">
                <span class="mr-5 currency dark-color">{{ currency }}</span>
                <a-input
                  placeholder=""
                  v-model="price"
                  type="number"
                  @focus="$event.target.select()"
                /></div
            ></a-col>
          </a-row>
          <!-- <a-row :gutter="16">
            <a-col :span="24" class="product-item__form-item"
              ><label>Notes</label>
              <div class="position-r">
                <a-textarea
                  placeholder="What is changing between tests?"
                  v-model="notes"
                  @focus="$event.target.select()"
                  :auto-size="{ minRows: 4 }"
                />
              </div>
            </a-col>
          </a-row> -->
        </div>
      </div>
      <div class="flex justify-between">
        <!-- <p
          class="product-item__required cursor-pointer"
          @click="$emit('image-requirements-modal')"
          v-if="productInfo.FriendlyName !== 'Control'"
        >
          <a-icon type="info-circle" /> Image Requirements
        </p> -->
        <!-- <p
          class="mb-0 product-item__control-info"
          v-if="productInfo.FriendlyName === 'Control'"
        >
          No need to make changes to the Control version of this product. Add a
          new version by clicking the button 'Add Test'.
        </p> -->
        <!-- <a-button type="primary" @click="$emit('close-product-item')">
          Done
        </a-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from "@/components/packTestSurvey/ImageUpload.vue";
export default {
  name: "EditProductItemNew",
  components: { ImageUpload },
  props: {
    productInfo: {
      type: Object,
    },
    unEncryptSurveyId: {
      type: Number,
    },
    index: {
      type: Number,
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    price: {
      get() {
        return this.productInfo.Price;
      },
      set(price) {
        this.$emit("input", { ...this.productInfo, Price: price });
      },
    },
    notes: {
      get() {
        return this.productInfo.Notes;
      },
      set(notes) {
        this.$emit("input", { ...this.productInfo, Notes: notes });
      },
    },
  },
  watch: {},
  methods: {
    setImageUrl(url) {
      this.$emit("input", { ...this.productInfo, ImageURL: url });
    },
    deleteItem() {
      let _this = this;
      this.$confirm({
        title: "Delete pack?",
        content: () => <div>Are you sure you want to delete?</div>,
        onCancel() {
          _this.$emit("delete");
        },
        okText: "No",
        cancelText: "Yes, delete",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  max-width: 94px;
  margin: 0 auto;
  &__inner {
    position: relative;
    background-clip: padding-box;
    border: 0;
    pointer-events: auto;
    width: 100%;
  }
  &:last-child {
    border-bottom: 0;
  }
  &__delete {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  &__required {
    font-size: 12px;
  }
  &__image {
    margin-bottom: 10px;
    .image-upload {
      min-width: 94px;
      max-width: 94px;
      height: 100px;
      &:after {
        width: 32px;
        height: 32px;
        top: 8px;
      }
      &__info {
        max-width: 80px;
        font-size: 11px;
      }
    }
  }
  &__form {
    flex: 1;
    .currency {
      margin-left: -12px;
    }
  }
  &__form-wrapper {
    padding: 27px 0 20px;
    border-bottom: 1px solid #e1e1e1;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__form-item {
    label {
      color: #545454;
      margin-bottom: 1px;
      display: block;
      white-space: nowrap;
      font-size: 12px;
      text-align: left;
    }
    .ant-input {
      width: 94px;
      padding: 0 5px;
      &[disabled] {
        background: #fff;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    textarea {
      resize: none;
    }
  }
  &__control-info {
    font-size: 11px;
    line-height: 14px;
  }
}
</style>
