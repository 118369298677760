var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stimuli-summary"},[_c('StimuliTitle',{attrs:{"title":"Define the priority product for packaging diagnostics. Standard approach includes sample for one package, but you can identify more for an incremental cost."}}),_c('div',{staticClass:"flex stimuli-summary-products"},_vm._l((_vm.productsDetailsInfo.zones),function(item,index){return _c('div',{key:item.packs[0].UPC,staticClass:"stimuli-summary-products__item"},[_c('div',{staticClass:"stimuli-summary-products__item-inner"},[(item.deepDive)?_c('div',{staticClass:"stimuli-summary-products__item-flag"},[_vm._v(" Priority Product ")]):_vm._e(),_c('div',{staticClass:"stimuli-summary-products__item-image image-loading"},[_c('img',{attrs:{"data-v-2eca84ac":"","src":item.packs[0].ImageURL},on:{"load":function($event){return $event.target.parentNode.classList.toggle(
                'image-loading--loaded'
              )}}})]),_c('div',{staticClass:"stimuli-summary-products__item-info"},[_vm._v(" "+_vm._s(item.packs[0].ProductName)+" ")]),_c('div',{staticClass:"stimuli-summary-products__item-select"},[_c('div',[_c('a-select',{key:Math.random(),staticClass:"fullWidth",attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":item.deepDive
                  ? 'Selected for deep dive analysis'
                  : 'NOT selected for deep dive analysis',"value":item.deepDive
                  ? 'Selected for deep dive analysis'
                  : 'NOT selected for deep dive analysis'},on:{"change":function($event){return _vm.$emit('setDeepDive', {
                  index: index,
                  deepDive: $event,
                })}}},_vm._l((_vm.deepDive),function(item){return _c('a-select-option',{key:item.title,attrs:{"value":item.status}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)])])])}),0),_c('div',{staticClass:"flex justify-between stimuli-nav"},[_c('a-button',{on:{"click":function($event){return _vm.$emit('prevStep')}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" Upload Test Images")],1),_c('a-button',{attrs:{"type":"primary","disabled":_vm.disableNextStep === undefined},on:{"click":function($event){return _vm.$emit('nextStep')}}},[_vm._v(" Define"),_c('strong',[_vm._v(" Superiority ")]),_vm._v("Benchmark "),_c('font-awesome-icon',{attrs:{"icon":"arrow-right"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }