<template>
  <div class="stimuli-title">
    <h2 v-html="title" :style="{ maxWidth: maxWidth + 'px' }"></h2>
  </div>
</template>

<script>
export default {
  name: "StimuliTitle",
  components: {},
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    maxWidth: {
      type: Number,
      default() {
        return 800;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.stimuli-title {
  margin-bottom: 55px;
  @media screen and (max-width: 980px) {
    margin-bottom: 35px;
  }
  h2 {
    font-size: 18px;
    font-weight: 400;
    max-width: 800px;
    line-height: 28px;
    strong {
      font-weight: 500;
    }
    @media screen and (max-width: 980px) {
      font-size: 16px;
      line-height: 26px;
    }
    @media screen and (max-width: 800px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
