<template>
  <div class="stimuli-summary">
    <StimuliTitle
      title="Define the priority product for packaging diagnostics. Standard approach includes sample for one package, but you can identify more for an incremental cost."
    ></StimuliTitle>

    <div class="flex stimuli-summary-products">
      <div
        class="stimuli-summary-products__item"
        v-for="(item, index) in chosenProductsList"
        :key="item.productName"
      >
        <div class="stimuli-summary-products__item-inner">
          <div
            class="stimuli-summary-products__item-flag"
            v-if="productsDetailsInfo.zones[index].deepDive"
          >
            Priority Product
          </div>
          <div class="stimuli-summary-products__item-image image-loading">
            <img
              data-v-2eca84ac=""
              :src="item.ImageURL"
              @load="
                $event.target.parentNode.classList.toggle(
                  'image-loading--loaded'
                )
              "
            />
          </div>
          <div class="stimuli-summary-products__item-info">
            {{ item.productName }}
          </div>
          <div class="stimuli-summary-products__item-select">
            <div>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                placeholder="Please select"
                :key="Math.random()"
                @change="
                  $emit('setDeepDive', {
                    index: index,
                    deepDive: $event,
                  })
                "
                :default-value="
                  productsDetailsInfo.zones[index].deepDive
                    ? 'Selected for deep dive analysis'
                    : 'NOT selected for deep dive analysis'
                "
                :value="
                  productsDetailsInfo.zones[index].deepDive
                    ? 'Selected for deep dive analysis'
                    : 'NOT selected for deep dive analysis'
                "
              >
                <a-select-option
                  v-for="item in deepDive"
                  :key="item.title"
                  :value="item.status"
                >
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between stimuli-nav">
      <a-button @click="$emit('prevStep')"
        ><font-awesome-icon icon="arrow-left" /> Upload Test Images</a-button
      >
      <a-button
        type="primary"
        @click="$emit('nextStep')"
        :disabled="disableNextStep === undefined"
      >
        Define<strong> Superiority </strong>Benchmark
        <font-awesome-icon icon="arrow-right"
      /></a-button>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";

export default {
  name: "StimuliDeepDive",
  components: {
    StimuliTitle,
  },
  props: {
    chosenProductsList: {
      type: Array,
      default() {
        return [];
      },
    },
    productsDetailsInfo: {
      type: Object,
    },
  },
  data() {
    return {
      deepDive: [
        { title: " Selected for deep dive analysis", status: "yes" },
        { title: " NOT selected for deep dive analysis", status: "no" },
      ],
    };
  },
  computed: {
    disableNextStep() {
      return this.productsDetailsInfo.zones.find(
        (item) => item.deepDive === true
      );
    },
  },
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.stimuli-summary-products {
  margin: 0 -10px;
  flex-wrap: wrap;
  padding-bottom: 70px;
  &__item {
    padding: 10px;
    width: 20%;
    @media screen and (max-width: 1500px) {
      width: 30%;
    }
    @media screen and (max-width: 1200px) {
      width: 33.33%;
    }
    @media screen and (max-width: 980px) {
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  &__item-inner {
    border: 1px solid #f1f1f1;
    padding: 30px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__item-flag {
    padding: 0 6px;
    position: absolute;
    top: 10px;
    right: 0;
    background: green;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }
  &__item-image {
    width: 110px;
    height: 110px;
    text-align: center;
    display: flex;
    margin: 0 auto 20px;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 105px;
    }
  }
  &__item-info {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-bottom: 11px;
    flex: 1;
    position: relative;
  }
  &__item-select {
    width: 100%;
    ::v-deep .ant-select {
      .ant-select-selection--single {
        height: 40px;
        border-color: #b0b0b0;
        .ant-select-selection__rendered {
          line-height: 38px;
        }
      }
    }
    label {
      font-size: 12px;
      color: #000;
    }
  }
}
</style>
