<template>
  <div class="product-list">
    <StimuliTitle
      title="The files you provided have been processed and there looks to be some information missing. Before proceeding, please correct any missing fields for the products below."
    ></StimuliTitle>

    <div
      class="flex items-center justify-between pt-0 management-system__tools"
    >
      <Skeleton v-if="loadingFilters"></Skeleton>
      <div class="flex items-center management-system__filters">
        <div
          class="management-system__filters-item"
          v-if="productsList.length && viewType === 'list'"
        >
          <label>Search products</label>
          <div class="position-r">
            <font-awesome-icon icon="search" />
            <a-input
              :class="{ active: searchParametars != '' }"
              v-model="searchParametars"
              placeholder="Insert product name"
            >
              <a-icon
                slot="addonAfter"
                theme="filled"
                type="close-circle"
                v-show="searchParametars != ''"
                @click="searchParametars = ''"
            /></a-input>
          </div>
        </div>
      </div>
    </div>
    <div class="management-system__table" v-if="viewType === 'list'">
      <a-table
        tableLayout="auto"
        class="custom-table"
        :columns="columns"
        :row-key="(record) => record.UPC"
        :data-source="searchProductList"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template slot="Price" slot-scope="record">
          <span
            v-if="
              record.Price !== '' && record.Price !== '0' && !record.PriceEdited
            "
            >{{ record.Price }}</span
          >
          <div v-else class="flex items-center">
            <span class="dark-color">{{ currency }}</span>
            <a-input
              placeholder="0.0"
              v-model="record.Price"
              @change="handleChange($event.target.value, record.UPC, 'Price')"
              class="ml-5 mr-5"
              type="number"
            />
            <span class="required">*</span>
          </div>
        </template>
      </a-table>
    </div>

    <div class="flex justify-between stimuli-nav">
      <a-button @click="prevStep"
        ><font-awesome-icon icon="arrow-left" /> Upload shelf data</a-button
      >
      <a-button type="primary" :disabled="hasEmptyValues" @click="nextStep"
        >Select Test Products <font-awesome-icon icon="arrow-right"
      /></a-button>
      <!-- <a-button type="primary" @click="nextStep"
        >Select Test Products <font-awesome-icon icon="arrow-right"
      /></a-button> -->
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";

const columns = [
  {
    title: "Product",
    dataIndex: "ProductName",
  },
  {
    title: "Brand",
    dataIndex: "Brand",
  },
  {
    title: "Price",
    scopedSlots: { customRender: "Price" },
  },
];

export default {
  name: "EditProductList",
  components: {
    StimuliTitle,
    Skeleton,
  },
  props: {
    productsList: {
      type: Array,
      default() {
        return [];
      },
    },
    currency: {
      type: String,
      default() {
        return "$";
      },
    },
  },
  data() {
    return {
      brandList: [],
      columns,
      pagination: {},
      selectedRowKeys: [],
      viewType: "list",
      previewShelfKey: null,
      noBrandProducts: false,
      searchParametars: "",
      loading: false,
      productsQuickPreview: false,
      loadingFilters: true,
      showBrandDropdown: false,
      loadingBrandList: false,
    };
  },
  computed: {
    searchProductList() {
      return this.productsList.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every((v) => item.ProductName.toLowerCase().includes(v));
      });
    },
    hasEmptyValues() {
      return this.productsList.some((item) => item.Price === "");
    },
  },
  methods: {
    handleChange(value, UPC, property) {
      const index = this.productsList.findIndex((item) => item.UPC === UPC);
      if (index !== -1) {
        this.productsList[index][property] = value;
        this.productsList[index][property + "Edited"] = true;
      }
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    nextStep() {
      this.$emit("nextStep");
      this.updatePriceList();
    },
    prevStep() {
      this.$emit("prevStep");
    },
    setPagination() {
      const pagination = { ...this.pagination };
      pagination.total = this.productsList.length;
      pagination.hideOnSinglePage = true;
      this.pagination = pagination;
    },
    updatePriceList() {
      let priceList = this.productsList
        .filter(function (obj) {
          return obj.Price !== "";
        })
        .map(function (obj) {
          return { UPC: obj.UPC, Price: obj.Price };
        });
      const updatedUPCPriceList = new Promise((resolve) => {
        wsUtils.UpdatedUPCPriceList(
          {
            SurveyID: this.$route.params.id,
            UpdatedUPCPriceList: priceList,
          },
          resolve
        );
      });
      updatedUPCPriceList.then(() => {});
    },
  },
  created() {
    this.setPagination();

    setTimeout(() => {
      this.loadingFilters = false;
    }, 800);
  },
};
</script>

<style lang="scss" scoped>
.product-list {
  padding-bottom: 70px;
  &__table-cta {
    .ant-btn {
      height: 33px;
    }
  }
  ::v-deep .custom-table {
    thead {
      border-color: #fff;
      .ant-table-selection {
        display: none;
      }
      th:last-child {
      }
    }
    tbody {
      tr {
        border-color: #fff;
        &:hover {
          cursor: default;
        }
        td {
          &:nth-child(2) {
            min-width: 230px;
          }
          &:last-child {
            min-width: 168px;
            width: 168px;
          }
          &.ant-table-selection-column {
            > span {
              pointer-events: none;
            }
          }
          .ant-input {
            // border-color: red;
            max-width: 168px;
          }
          .required {
            color: red;
          }
        }
        &.ant-table-row-selected td {
          background: #e7f4fb !important;
        }
      }
    }
    &.disable-select {
      tr:not(.ant-table-row-selected) {
        .ant-table-selection-column span {
          pointer-events: none;
        }
      }
    }
  }
  .management-system {
    &__tools {
      background: #fafafa;
      padding: 5px 15px 15px 15px;
      border-radius: 4px;
      min-height: 84px;
      position: relative;
      margin-bottom: 25px;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
    &__filters-item {
      > * {
        color: #4c4c4c;
      }
      ::v-deep .ant-select {
        .ant-select-selection {
          &--single {
            border-color: #b0b0b0;
          }
          &__placeholder {
            color: #b0b0b0;
          }
        }
      }
      ::v-deep .ant-input {
        border-color: #b0b0b0;
      }
      &--brand {
        display: none;
      }
      &--brand-show {
        display: block;
      }
      &--search {
        margin-left: 90px;
        position: relative;
        &:after {
          position: absolute;
          left: -50px;
          top: 32px;
          height: 22px;
          width: 1px;
          content: "";
          background: #bababa;
        }
      }
    }
  }
}
</style>
