<template>
  <div class="competitive-benchmark">
    <StimuliTitle
      title="Please select the relevant competitive product for each test package."
    ></StimuliTitle>

    <div
      v-for="item in chosenProducts"
      :key="item.areaID"
      class="flex justify-between"
    >
      <div class="competitive-benchmark__item">
        <div class="competitive-benchmark__item-inner">
          <div class="competitive-benchmark__item-flag" v-if="item.deepDive">
            Priority Product
          </div>
          <div class="competitive-benchmark__item-image image-loading">
            <img
              :src="item.ImageURL"
              @load="
                $event.target.parentNode.classList.toggle(
                  'image-loading--loaded'
                )
              "
            />
          </div>
          <div class="competitive-benchmark__item-info">
            {{ item.productName }}
          </div>
          <div class="competitive-benchmark__item-select">
            <div>
              <a-button type="primary" @click="openCompetitorModal(item)">
                <span
                  v-if="
                    chosenProductsList.hasOwnProperty('product' + item.zoneId)
                  "
                  >Change competitor pack</span
                >

                <span v-else>Select competitor pack</span>
              </a-button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          competitive-benchmark__item
          competitive-benchmark__item--competitor-pack
        "
        :class="{
          'competitive-benchmark__item--dark':
            chosenProductsList.hasOwnProperty('product' + item.zoneId),
        }"
      >
        <div class="competitive-benchmark__item-inner">
          <div
            class="competitive-benchmark__item-image"
            v-if="chosenProductsList.hasOwnProperty('product' + item.zoneId)"
          >
            <img
              :src="
                chosenProductsList.hasOwnProperty('product' + item.zoneId)
                  ? chosenProductsList['product' + item.zoneId].ImageURL
                  : ''
              "
            />
          </div>
          <div class="competitive-benchmark__item-info">
            {{
              chosenProductsList.hasOwnProperty("product" + item.zoneId)
                ? chosenProductsList["product" + item.zoneId].productName ||
                  chosenProductsList["product" + item.zoneId].ProductName
                : ""
            }}
          </div>

          <div
            class="general-checkbox flex items-center"
            v-if="chosenProductsList.hasOwnProperty('product' + item.zoneId)"
          >
            <div class="mr-10">
              <a-tooltip
                placement="top"
                overlayClassName="ant-tooltip--step-nav"
              >
                <template slot="title">
                  <span
                    >Adding pack diagnostics for this package this will increase
                    the overall sample size for your study adding incremental
                    costs.
                  </span>
                </template>

                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <input
              type="checkbox"
              :id="item.zoneId"
              @change="
                $emit('competitive-products', chosenProductsList),
                  $emit('enable-deep-dive')
              "
              v-model="
                chosenProductsList['product' + item.zoneId].CompetitiveDeepDive
              "
            />
            <label :for="item.zoneId"
              >Enable Additional Pack Diagnostics
            </label>
          </div>
          <div
            class="competitive-benchmark__item-delete"
            v-if="chosenProductsList.hasOwnProperty('product' + item.zoneId)"
            @click="deleteChosenProduct(item.zoneId)"
          >
            <a-icon type="close" />
          </div>

          <p v-if="!chosenProductsList.hasOwnProperty('product' + item.zoneId)">
            No competitor pack selected.
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-between stimuli-nav">
      <a-button @click="$emit('prevStep')"
        ><font-awesome-icon icon="arrow-left" />
        <span v-if="activeDeepDiveStep"
          >Identify <strong>Priority</strong> Products</span
        >
        <span v-else>Upload Test Images</span>
      </a-button>
    </div>

    <a-modal
      :visible="competitorModal"
      @cancel="closeCompetitorModal"
      centered
      :footer="null"
      :header="false"
      wrapClassName="ant-modal--competitive-benchmark"
    >
      <p class="into-text">
        Please select the relevant competitive product for
        {{ activeProductName }}.
      </p>
      <div class="management-system__tools">
        <div class="flex items-end management-system__filters">
          <div class="management-system__filters-item">
            <label>Brand</label>
            <a-select
              class="fullWidth"
              option-filter-prop="children"
              show-search
              placeholder="Please select"
              :key="Math.random()"
              @change="setBrand"
              :default-value="
                chosenBrand['table' + activeTableId] || 'All brands'
              "
            >
              <a-select-option :key="Math.random()" value="All brands"
                >All brands</a-select-option
              >
              <a-select-option
                v-for="item in brandList"
                :key="item.BrandName"
                :value="item.BrandName"
                :disabled="
                  productsDetailsInfo.Brand !== null
                    ? item.BrandName.toLowerCase() ===
                      productsDetailsInfo.Brand.toLowerCase()
                    : false
                "
              >
                {{ item.BrandName }}
              </a-select-option>
            </a-select>
          </div>
          <div
            class="
              management-system__filters-item
              management-system__filters-item--search
            "
            v-if="
              brandProducts.hasOwnProperty('table' + activeTableId)
                ? brandProducts['table' + activeTableId].length > 0
                  ? true
                  : false
                : false
            "
          >
            <label>Search products</label>
            <div class="position-r">
              <font-awesome-icon icon="search" />
              <a-input
                :class="{ active: searchParametars != '' }"
                v-model="searchParametars"
                placeholder="Insert product name"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="close-circle"
                  v-show="searchParametars != ''"
                  @click="searchParametars = ''"
              /></a-input>
            </div>
          </div>
        </div>
      </div>
      <div
        class="management-system__table"
        :class="{ 'management-system__table--disable': setAllProductsInfo }"
        v-for="item in chosenProducts"
        :key="item.zoneId"
      >
        <a-table
          v-if="
            item.zoneId === activeTableId &&
            brandProducts['table' + activeTableId].length
          "
          tableLayout="auto"
          class="custom-table"
          :columns="columns"
          :row-key="(record) => record.areaID"
          :data-source="searchProductList"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          :row-class-name="
            (_record) => (_record.selected ? 'row-selected' : null)
          "
          :row-selection="rowSelection"
          :custom-row="customRow"
          :class="{
            'disable-select':
              selectedRowKeys['table' + activeTableId].length === 1,
            'custom-table--remove-shadow':
              brandProducts['table' + activeTableId].length < 4,
          }"
          :scroll="{
            y: brandProducts['table' + activeTableId].length <= 10 ? 380 : 320,
          }"
        >
          <div
            slot="Image"
            class="competitive-benchmark__image image-loading"
            slot-scope="record"
          >
            <img
              :src="record.ImageURL"
              @load="
                $event.target.parentNode.classList.toggle(
                  'image-loading--loaded'
                )
              "
            />
          </div>
        </a-table>

        <DataInfo
          v-if="noBrandProducts"
          :border0="true"
          :paddingTop="100"
          :paddingBottom="100"
          text="
           There are no products for the selected brand...
          "
        ></DataInfo>
        <Loader
          text="Loading products..."
          v-if="loading && item.zoneId === activeTableId"
          :paddingTop="150"
        ></Loader>
      </div>
      <div
        class="competitive-benchmark__copy-product"
        v-if="setAllProductsInfo"
      >
        <a-icon type="question-circle" />
        <span>Use this competitive benchmark for all test products</span>
        <div>
          <button @click="setAllProducts" class="btn-reset">Yes</button> /
          <button class="btn-reset" @click="closeCompetitorModal">No</button>
        </div>
      </div>
      <div class="flex justify-end mt-15 competitive-benchmark__modal-cta">
        <a-button
          type="primary"
          @click="(competitorModal = false), (activeTableSelectedRows = [])"
          >Done
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import DataInfo from "@/components/general/DataInfo.vue";
import _ from "lodash";
import Loader from "@/components/general/Loader.vue";

const columns = [
  {
    title: "",
    scopedSlots: { customRender: "Image" },
  },
  {
    title: "Product",
    dataIndex: "productName",
  },
  {
    title: "Weight",
    dataIndex: "weight",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
];

export default {
  name: "StimuliCompetitiveBenchmark",
  components: {
    StimuliTitle,
    DataInfo,
    Loader,
  },
  props: {
    deepDiveProducts: {
      type: Array,
    },
    chosenProducts: {
      type: Array,
    },
    brandList: {
      type: Array,
    },
    shelfImageId: {
      type: Number,
    },
    shelfId: {
      type: Number,
    },
    activeBrand: {
      type: String,
    },
    activeStep: {
      type: Number,
    },
    productsDetailsInfo: {
      type: Object,
    },
    editableSurvey: {
      type: Boolean,
    },
    activeDeepDiveStep: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      competitorModal: false,
      chosenBrand: {},
      loading: false,
      brandProducts: {},
      pagination: {},
      noBrandProducts: false,
      searchParametars: "",
      selectedRowKeys: {},
      chosenProductsList: {},
      columns,
      activeTableId: null,
      activeTableSelectedRows: [],
      refreshKey: 0,
      disableNextStep: false,
      editModeSet: false,
      tempChosenProduct: null,
      setAllProductsInfo: false,
      activeProductName: null,
    };
  },
  watch: {
    searchParametars() {
      this.pagination.total = this.searchProductList.length;
    },
    activeStep(newVal) {
      if (newVal === 5) {
        this.setData();
        if (this.editableSurvey && !this.editModeSet) {
          this.setEditMode();
        }
        this.$emit("competitive-products", this.chosenProductsList);
        this.nextStep();
      }
    },
  },
  computed: {
    searchProductList() {
      this.refreshKey;
      return this.brandProducts["table" + this.activeTableId].filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every((v) => item.productName.toLowerCase().includes(v));
      });
    },
    rowSelection() {
      return {
        selectedRowKeys: this.activeTableSelectedRows,
      };
    },
  },
  methods: {
    openCompetitorModal(item) {
      this.activeProductName = item.productName;
      this.competitorModal = true;
      this.activeTableId = item.zoneId;
      if (this.brandProducts["table" + this.activeTableId].length === 0) {
        this.getProducts("all");
      }
      this.activeTableSelectedRows = [
        ...this.selectedRowKeys["table" + this.activeTableId],
      ];
    },
    closeCompetitorModal() {
      this.activeProductName = null;
      this.competitorModal = false;
      this.activeTableSelectedRows = [];
      this.searchParametars = "";
      this.setAllProductsInfo = false;
    },
    deleteChosenProduct(productId) {
      let _this = this;
      this.$confirm({
        title: "Delete product",
        content: () => (
          <div>Are you sure you want to delete a chosen product?</div>
        ),
        onCancel() {
          const productList = { ..._this.chosenProductsList };
          delete productList["product" + productId];
          _this.chosenProductsList = productList;
          _this.selectedRowKeys["table" + productId] = [];
          _this.nextStep();
          _this.$emit("competitive-products", _this.chosenProductsList);
        },
        okText: "No",
        cancelText: "Yes",
      });
    },
    setBrand(brand) {
      this.chosenBrand["table" + this.activeTableId] = brand;
      if (brand === "All brands") {
        this.getProducts("all");
      } else {
        this.getProducts();
      }
    },
    getProducts(type) {
      if (
        this.chosenBrand["table" + this.activeTableId] !== undefined ||
        type === "all"
      ) {
        this.loading = true;
        this.brandProducts["table" + this.activeTableId] = [];
        const getShelfBrandProducts = new Promise((resolve) => {
          wsUtils.GetShelfBrandProducts(
            {
              ImageId: this.shelfImageId,
              BrandName:
                type === "all"
                  ? ""
                  : this.chosenBrand["table" + this.activeTableId],
              shelfId: this.shelfId,
              excludedBrand: this.activeBrand,
            },
            resolve
          );
        });

        getShelfBrandProducts.then((data) => {
          this.brandProducts["table" + this.activeTableId] = data;
          const pagination = { ...this.pagination };
          pagination.total = data.length;
          pagination.hideOnSinglePage = true;
          pagination.defaultPageSize = 25;
          this.pagination = pagination;
          this.loading = false;
          data.length
            ? (this.noBrandProducts = false)
            : (this.noBrandProducts = true);
          this.refreshKey = Math.random();
        });
        this.selectedRowKeys["table" + this.activeTableId] = [];
      } else {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "Please select a brand from the dropdown list then click on the 'Search Products' button.",
          placement: "bottomLeft",
          duration: 5,
        });
      }
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selectRow(record);
          },
        },
      };
    },
    selectRow(record) {
      let currentRowKeys = this.selectedRowKeys["table" + this.activeTableId];
      const selectedRowKeys = [...currentRowKeys];
      if (selectedRowKeys.indexOf(record.areaID) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.areaID), 1);
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        if (this.selectedRowKeys["table" + this.activeTableId].length < 1) {
          selectedRowKeys.push(record.areaID);
          setTimeout(() => {
            this.setProductsDetails();
          }, 0);
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description: "The maximum number of selected products is 1.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      }
      this.selectedRowKeys["table" + this.activeTableId] = selectedRowKeys;
      this.activeTableSelectedRows = selectedRowKeys;
    },
    setProductsDetails() {
      let product;
      this.selectedRowKeys["table" + this.activeTableId].forEach((item) => {
        product = this.brandProducts["table" + this.activeTableId].find(
          (product) => product.areaID === item
        );

        if (product !== undefined) {
          product.parentZoneId = this.activeTableId;
          this.chosenProductsList["product" + this.activeTableId] = product;
          this.tempChosenProduct = product;
        }
      });
      if (product === undefined) {
        const productList = { ...this.chosenProductsList };
        delete productList["product" + this.activeTableId];
        this.chosenProductsList = productList;
      }
      this.setAllProductsInfo =
        Object.keys(this.chosenProductsList).length !==
          this.chosenProducts.length &&
        this.chosenProducts.length > 1 &&
        Object.keys(this.chosenProductsList).length === 1;
      this.nextStep();
      this.$emit("competitive-products", this.chosenProductsList);
    },
    setAllProducts() {
      this.chosenProducts.forEach((item) => {
        this.chosenProductsList["product" + item.zoneId] =
          this.tempChosenProduct;
        this.chosenProductsList["product" + item.zoneId] = Object.assign(
          {},
          this.tempChosenProduct,
          {
            parentZoneId: item.zoneId,
          }
        );
      });

      this.$forceUpdate();
      this.$emit("competitive-products", this.chosenProductsList);
      this.closeCompetitorModal();
      this.nextStep();
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    setData() {
      let productsZoneID = [];
      let tableZoneID = [];
      this.chosenProducts.forEach((item) => {
        if (this.selectedRowKeys["table" + item.zoneId] === undefined) {
          this.selectedRowKeys["table" + item.zoneId] = [];
        }
        if (this.brandProducts["table" + item.zoneId] === undefined) {
          this.brandProducts["table" + item.zoneId] = [];
        }
        productsZoneID.push("product" + item.zoneId);
        tableZoneID.push("table" + item.zoneId);
      });

      this.brandProducts = _.pick(this.brandProducts, tableZoneID);
      this.chosenBrand = _.pick(this.chosenBrand, tableZoneID);
      this.chosenProductsList = _.pick(this.chosenProductsList, productsZoneID);
      this.selectedRowKeys = _.pick(this.selectedRowKeys, tableZoneID);
    },
    nextStep() {
      if (
        Object.keys(this.chosenProductsList).length ===
        this.chosenProducts.length
      ) {
        this.disableNextStep = false;
      } else {
        this.disableNextStep = true;
      }
      this.$emit("disable-next-step", this.disableNextStep);
    },
    setEditMode() {
      this.productsDetailsInfo.zones.forEach((item) => {
        if (
          item.CompetitiveProduct !== undefined &&
          item.CompetitiveProduct.ZoneID !== 0
        ) {
          this.chosenProductsList["product" + item.ZoneID] =
            item.CompetitiveProduct;
          this.chosenProductsList["product" + item.ZoneID].CompetitiveDeepDive =
            item.CompetitiveDeepDive;
          this.chosenProductsList["product" + item.ZoneID].parentZoneId =
            item.ZoneID;
        } else {
          delete this.chosenProductsList["product" + item.ZoneID];
        }
      });
      this.editModeSet = true;
    },
  },
  created() {
    if (this.activeStep === 5) {
      this.setData();
      if (this.editableSurvey && !this.editModeSet) {
        this.setEditMode();
      }
      this.$emit("competitive-products", this.chosenProductsList);
      this.nextStep();
    }
  },
};
</script>

<style lang="scss" scoped>
.competitive-benchmark {
  padding-bottom: 65px;
  &__item {
    width: 47.5%;
    margin-bottom: 15px;
    background: #fff;
    z-index: 2;
    &--competitor-pack {
      z-index: 1;
      position: relative;
      min-height: 107px;
      .competitive-benchmark__item-inner {
        border-style: dashed;
        border-color: #f3f3f3;
        overflow: visible;
      }
      &:after {
        width: 50%;
        border-top: 1px dashed #f3f3f3;
        position: absolute;
        right: 100%;
        bottom: 50%;
        content: "";
      }
    }
    &--dark {
      .competitive-benchmark__item-inner {
        border-color: #dcdbdb;
      }
      &:after {
        border-top: 1px dashed #dcdbdb;
      }
    }
  }
  &__item-delete {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    top: -14px;
    right: -14px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    border: 1px dashed #dcdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;
    transition: background 0.25s ease;
    &:hover {
      background: #f3f3f3;
    }
  }
  &__item-inner {
    border: 1px solid #f1f1f1;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    p {
      margin: 0;
      font-size: 13px;
      color: #959595;
      text-align: center;
      width: 100%;
      font-style: italic;
    }
    .general-checkbox {
      margin: 0;
      label {
        display: inline-flex;
        background: #f3f3f3;
        padding: 3px 4px 3px 24px;
        border-radius: 4px;
        font-size: 13px;
        &:after {
          background: #fff;
          left: 4px;
          top: 5px;
        }
        &:before {
          left: 7px;
          top: 7px;
        }
      }
    }
    @media screen and (max-width: 980px) {
      flex-wrap: wrap;
    }
  }
  &__item-flag {
    padding: 0 6px;
    position: absolute;
    top: 0;
    right: 0;
    background: green;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
  }
  &__item-image {
    width: 100px;
    min-width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1200px) {
      width: 65px;
      min-width: 65px;
    }
    img {
      max-width: 100%;
      max-height: 60px;
    }
  }
  &__item-info {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #000;
    flex: 1;
    position: relative;
    padding: 0 20px 0 15px;
    @media screen and (max-width: 1200px) {
      font-size: 13px;
    }
  }
  &__item-select {
    @media screen and (max-width: 980px) {
      flex: 0 0 100%;
      display: flex;
      margin-top: 10px;
      padding-left: 80px;
    }
    .ant-btn {
      min-width: 185px;
      @media screen and (max-width: 1200px) {
        min-width: 120px;
        width: 120px;
        white-space: normal;
        font-size: 13px;
        line-height: 13px;
        padding: 2px 10px;
      }
      @media screen and (max-width: 980px) {
        white-space: nowrap;
        width: auto;
      }
    }
  }
  &__image {
    width: 70px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 60px;
    }
  }
  &__modal-cta {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 12px 25px;
    border-top: 1px solid #f0f0f0;
  }
  &__copy-product {
    position: absolute;
    padding: 15px 28px;
    border-radius: 8px;
    box-shadow: 0 0 26px 4px rgba(0, 0, 0, 0.09);
    background: #fff;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 248px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: block;
      margin-bottom: 4px;
    }
    button {
      margin: 0 4px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;
    }
    .anticon {
      font-size: 24px;
      margin-bottom: 8px;
      color: #3c90e6;
    }
  }
}
::v-deep .custom-table {
  tbody {
    tr {
      td {
        height: 76px;
      }
    }
  }
  &--remove-shadow {
    .ant-table {
      &:after {
        display: none;
      }
    }
  }
  .ant-table {
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 28px;
      content: "";
      pointer-events: none;
    }
  }
  thead {
    border-color: #fff;
    .ant-table-selection {
      display: none;
    }
    tr {
      th {
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
          width: 100px;
          min-width: 100px;
        }
      }
    }
  }
  tbody {
    tr {
      border-color: #fff;
      td {
        padding: 10px;
        height: 86px;
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
          width: 100px;
          min-width: 100px;
        }
        &.ant-table-selection-column {
          > span {
            pointer-events: none;
          }
        }
      }
      &.ant-table-row-selected td {
        background: #e7f4fb !important;
      }
    }
  }
  &.disable-select {
    tr:not(.ant-table-row-selected) {
      .ant-table-selection-column span {
        pointer-events: none;
      }
    }
  }
  .ant-pagination {
    margin: 15px 0 0;
  }
}

::v-deep {
  .ant-modal {
    &--competitive-benchmark {
      padding-bottom: 0;
      .into-text {
        font-size: 16px;
        position: relative;
        padding-bottom: 25px;
        margin-bottom: 33px;
        &::after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 86px;
          border-bottom: 1px solid #d4d4d4;
          content: "";
        }
      }
      .ant-modal {
        max-width: 850px;
        width: 100% !important;
        padding: 15px;
      }
      .ant-modal-close {
        top: -12px;
        right: -14px;
      }
      .ant-modal-content {
        height: 750px;
        width: 100%;
        padding-bottom: 60px;
      }
      .management-system {
        &__tools {
          background: #fafafa;
          padding: 5px 15px 15px 15px;
          border-radius: 4px;
          margin-bottom: 15px;
        }
        &__filters-item {
          > * {
            color: #4c4c4c;
          }
          ::v-deep .ant-select {
            .ant-select-selection {
              &--single {
                border-color: #b0b0b0;
              }
              &__placeholder {
                color: #b0b0b0;
              }
            }
          }
          ::v-deep .ant-input {
            border-color: #b0b0b0;
          }
          &--search {
            margin-left: 90px;
            position: relative;
            &:after {
              position: absolute;
              left: -50px;
              top: 50%;
              height: 22px;
              width: 1px;
              content: "";
              background: #bababa;
            }
          }
        }
        &__view-cta {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
