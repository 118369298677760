<template>
  <div class="chosen-products-list">
    <StimuliTitle
      title="Please add additional designs in the columns below and upload the new pack images for each product."
    ></StimuliTitle>
    <a-row :gutter="16" v-show="false">
      <a-col :xs="{ span: 24 }" :sm="{ span: 12 }">
        <ActiveShelf
          :activeShelf="activeShelf"
          @editShelf="$emit('editShelf')"
        ></ActiveShelf
      ></a-col>
      <a-col :xs="{ span: 24 }" :sm="{ span: 12 }">
        <SelectedProducts
          :selectedProducts="chosenProductsList"
          @editSelectedProducts="$emit('prevStep')"
        ></SelectedProducts
      ></a-col>
    </a-row>
    <div>
      <div class="flex custom-table custom-table--chosen-products-list">
        <table class="w-full">
          <thead>
            <tr>
              <th>Products</th>
              <th v-for="(item, index) in getFirstZonePack" :key="index">
                <span v-if="index === 0">Control</span>
                <span v-else> Test {{ index }}</span>
              </th>
              <th>
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav"
                >
                  <template slot="title" v-if="findEmptyData.length > 0">
                    To add Test {{ getFirstZonePackLength }} you need to fill
                    all required fields for Test that you already added.
                  </template>
                  <a-button
                    @click="addCell"
                    type="primary"
                    :disabled="hasEmptyFields"
                    >Add Test {{ getFirstZonePackLength }}</a-button
                  >
                </a-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in productsDetailsInfo.zones"
              :key="item.ZoneID"
            >
              <td>
                {{
                  chosenProductsList.length > 0 &&
                  chosenProductsList[index] !== undefined
                    ? chosenProductsList[index].ProductName
                    : "/"
                }}
              </td>

              <td
                v-for="(pack, itemIndex) in item.packs"
                :key="itemIndex"
                class="text-center"
                :class="{ 'active-col-bcg': activeColumn === itemIndex }"
              >
                <div
                  class="chosen-products-list__delete-test"
                  v-if="index === 0 && pack.FriendlyName !== 'Control'"
                  @click="deleteProductInfo(itemIndex)"
                >
                  <a-tooltip
                    placement="top"
                    overlayClassName="ant-tooltip--step-nav"
                  >
                    <template slot="title">
                      Delete Test {{ itemIndex }}
                    </template>
                    <a-icon
                      type="close"
                      @mouseover="activeColumn = itemIndex"
                      @mouseout="activeColumn = null"
                    />
                  </a-tooltip>
                </div>
                <EditProductItemNew
                  :productInfo="pack"
                  :unEncryptSurveyId="unEncryptSurveyId"
                  :index="itemIndex"
                  :currency="currency"
                  @input="setProductInfo($event, index, itemIndex)"
                  @delete="deleteProductInfo(index, itemIndex)"
                  @image-requirements-modal="imageRequirementsModal = true"
                  @close-product-item="activeLegId = null"
                ></EditProductItemNew>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex justify-between stimuli-nav">
      <a-button @click="$emit('prevStep')"
        ><font-awesome-icon icon="arrow-left" /> Select Test Products</a-button
      >
      <a-button
        type="primary"
        @click="$emit('nextStep')"
        class="flex justify-center items-center"
        :disabled="hasEmptyFields"
      >
        <i class="circle-loader" v-if="loading"></i>

        <div v-else>
          <span v-if="activeDeepDiveStep"
            ><span class="mob-hide">Identify </span>
            <strong> Priority</strong> Products</span
          >
          <span v-else> Define<strong> Superiority </strong>Benchmark</span>

          <font-awesome-icon icon="arrow-right" /></div
      ></a-button>
    </div>
    <a-modal
      title="Image Requirements"
      :visible="imageRequirementsModal"
      @cancel="imageRequirementsModal = false"
      :footer="null"
      :width="920"
    >
      <div class="image-requirements">
        <div class="image-requirements__row">
          <p>
            1. The width or height of the image should be at least 800px (based
            on product shape)
          </p>
          <a-row :gutter="16" class="flex">
            <a-col :span="6"
              ><div class="image-requirements__item">
                <div class="image-requirements__item-category">
                  Square Shape
                </div>
                <a
                  href="/images/imageRequirements/s_product.jpeg"
                  target="_blank"
                >
                  <div class="image-requirements__item-image">
                    <img
                      src="/images/imageRequirements/s_product.jpeg"
                      alt=""
                    />
                  </div>
                  <p>Click the image to see large version</p>
                </a>
                <div class="flex image-requirements__item-dimensions">
                  <span class="flex-1">Width: 1000px</span>
                  <span class="flex-1">Height: 1000px</span>
                </div>
              </div></a-col
            >
            <a-col :span="6"
              ><div class="image-requirements__item">
                <div class="image-requirements__item-category">
                  Horizontal Rectangle Shape
                </div>
                <a
                  href="/images/imageRequirements/horizontal.jpeg"
                  target="_blank"
                >
                  <div class="image-requirements__item-image">
                    <img
                      src="/images/imageRequirements/horizontal.jpeg"
                      alt=""
                    />
                  </div>
                  <p>Click the image to see large version</p></a
                >
                <div class="flex image-requirements__item-dimensions">
                  <span class="flex-1">Width: 1000px</span>
                  <span class="flex-1">Height: 500px</span>
                </div>
              </div></a-col
            >
            <a-col :span="6"
              ><div class="image-requirements__item">
                <div class="image-requirements__item-category">
                  Vertical Rectangle Shape
                </div>
                <a
                  href="/images/imageRequirements/vertical.jpeg"
                  target="_blank"
                >
                  <div class="image-requirements__item-image">
                    <img src="/images/imageRequirements/vertical.jpeg" alt="" />
                  </div>
                  <p>Click the image to see large version</p></a
                >
                <div class="flex image-requirements__item-dimensions">
                  <span class="flex-1">Width: 1000px</span>
                  <span class="flex-1">Height: 500px</span>
                </div>
              </div></a-col
            >
            <a-col :span="6"
              ><div
                class="image-requirements__item image-requirements__item--red"
              >
                <div class="image-requirements__item-category">Bad Example</div>
                <a
                  href="/images/imageRequirements/badQuality.png"
                  target="_blank"
                >
                  <div class="image-requirements__item-image">
                    <img
                      src="/images/imageRequirements/badQuality.png"
                      style="max-width: 45%"
                      alt=""
                    />
                  </div>
                  <p>Click the image to see large version</p></a
                >
                <div class="flex image-requirements__item-dimensions">
                  <span class="flex-1">Width: 300px</span>
                  <span class="flex-1">Height: 300px</span>
                </div>
              </div></a-col
            >
          </a-row>
        </div>
        <div class="image-requirements__row">
          <p>
            2. The product image should be front-facing, sharp and of good
            quality. Typically, larger file sizes mean better resolution.
          </p>
          <a-row :gutter="16" class="flex">
            <a-col :span="6"
              ><div class="image-requirements__item">
                <div class="image-requirements__item-category">
                  Good Example
                </div>

                <a
                  class="image-requirements__item-image"
                  href="/images/imageRequirements/s_product.jpeg"
                  target="_blank"
                >
                  <img src="/images/imageRequirements/s_product.jpeg" alt="" />
                </a></div
            ></a-col>
            <a-col :span="6"
              ><div
                class="image-requirements__item image-requirements__item--red"
              >
                <div class="image-requirements__item-category">
                  Image with watermark
                </div>
                <a
                  class="image-requirements__item-image"
                  href="/images/imageRequirements/watermark.png"
                  target="_blank"
                >
                  <img src="/images/imageRequirements/watermark.jpeg" alt="" />
                </a></div
            ></a-col>
            <a-col :span="6"
              ><div
                class="image-requirements__item image-requirements__item--red"
              >
                <div class="image-requirements__item-category">
                  Blurry image
                </div>
                <a
                  class="image-requirements__item-image"
                  href="/images/imageRequirements/blurry.jpeg"
                  target="_blank"
                >
                  <img src="/images/imageRequirements/blurry.jpeg" alt="" />
                </a></div
            ></a-col>
            <a-col :span="6"
              ><div
                class="image-requirements__item image-requirements__item--red"
              >
                <div class="image-requirements__item-category">
                  Angled Image
                </div>
                <a
                  class="image-requirements__item-image"
                  href="/images/imageRequirements/angled.jpeg"
                  target="_blank"
                >
                  <img src="/images/imageRequirements/angled.jpeg" alt="" />
                </a></div
            ></a-col>
          </a-row>
          <h2>Image size</h2>
          <p class="font-size-14 mb-0">
            While some simple packs will look good even if your image file size
            is small, it is recommended that you use image files that are
            <strong>at least 50Kb</strong>
            to avoid quality bias and to ensure consistency across packs on the
            shelf.
          </p>
        </div>
        <div class="image-requirements__row">
          <p>
            3. The product image should be saved in "png" format with a
            transparent background.
          </p>
          <p class="font-size-14 mb-20">
            Providing image files with a transparent background allows the shelf
            to be assembled without human interaction. If the images CAN'T be
            provided in the required format, our QA processes will catch the
            issue and images can be sourced or optimized manually at an
            additional cost.
          </p>
          <a-row :gutter="16" class="flex">
            <a-col :span="12"
              ><div
                class="
                  image-requirements__item
                  image-requirements__item--transparent-info
                "
              >
                <div class="image-requirements__item-category">
                  Image with transparent background
                </div>
                <a
                  class="image-requirements__item-image"
                  href="/images/imageRequirements/woBG.png"
                  target="_blank"
                >
                  <img src="/images/imageRequirements/woBG.png" alt="" />
                </a></div
            ></a-col>
            <a-col :span="12"
              ><div
                class="
                  image-requirements__item
                  image-requirements__item--red
                  image-requirements__item--transparent-info
                "
              >
                <div class="image-requirements__item-category">
                  Image without transparent background
                </div>
                <a
                  class="image-requirements__item-image"
                  href="/images/imageRequirements/withBG.jpeg"
                  target="_blank"
                >
                  <img src="/images/imageRequirements/withBG.jpeg" alt="" />
                </a></div
            ></a-col>
          </a-row>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import EditProductItemNew from "@/components/packTestCustom/EditProductItemNew.vue";
import ActiveShelf from "@/components/packTestSurvey/ActiveShelf.vue";
import SelectedProducts from "@/components/packTestSurvey/SelectedProducts.vue";
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
// import * as wsUtils from "@/utils/wsUtils.js";
// import { mutations } from "@/utils/store.js";
import _ from "lodash";

const columns = [
  {
    title: "",
    scopedSlots: { customRender: "Image" },
  },
  {
    title: "Product",
    dataIndex: "ProductName",
  },
  {
    title: "Brand",
    dataIndex: "Brand",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
];

export default {
  name: "ChosenProductsList",
  components: {
    StimuliTitle,
    ActiveShelf,
    SelectedProducts,
    EditProductItemNew,
  },
  props: {
    chosenProductsList: {
      type: Array,
      default() {
        return [];
      },
    },
    unEncryptSurveyId: {
      type: Number,
    },
    surveyID: {
      type: String,
    },
    shelfID: {
      type: Number,
    },
    activeBrand: {
      type: String,
    },
    productsDetailsInfo: {
      type: Object,
    },
    activeShelf: {
      type: Object,
    },
    editableSurvey: {
      type: Boolean,
    },
    activeStep: {
      type: Number,
    },
    activeDeepDiveStep: {
      type: Boolean,
      default() {
        return false;
      },
    },
    currency: {
      type: String,
      default() {
        return "$";
      },
    },
  },
  data() {
    return {
      columns,
      productsDetails: {
        SurveyId: null,
        TemplateShelfID: null,
        Brand: null,
        zones: [],
      },
      loading: true,
      imageRequirementsModal: false,
      activeLegId: null,
      activeColumn: null,
    };
  },
  computed: {
    getFirstZonePack() {
      let packs = [];
      if (this.productsDetailsInfo.zones[0] !== undefined) {
        packs = this.productsDetailsInfo.zones[0].packs;
      }
      return packs;
    },
    getFirstZonePackLength() {
      let packsLength = 0;
      if (this.productsDetailsInfo.zones[0] !== undefined) {
        packsLength = this.productsDetailsInfo.zones[0].packs.length;
      }
      return packsLength;
    },
    findEmptyData() {
      return _.filter(this.productsDetailsInfo.zones, {
        packs: [{ ImageURL: "" }],
      });
    },
    hasEmptyFields() {
      return this.productsDetailsInfo.zones.some((zone) => {
        return zone.packs.some((pack) => {
          return pack.Price === "" || pack.ImageURL === "";
        });
      });
    },
  },
  methods: {
    addCell() {
      // let itemNumber = this.productsDetails.zones.find(
      //   (x) => x.ZoneID == ZoneID
      // ).packs.length;
      // let lastPack = this.productsDetails.zones.find((x) => x.ZoneID == ZoneID)
      //   .packs[itemNumber - 1];

      // delete lastPack.PackStimulusid;
      // delete lastPack.Id;

      // let newPack = { ...lastPack, FriendlyName: `Test ${itemNumber}` };
      // newPack.ImageURL = "";
      // this.productsDetails.zones
      //   .find((x) => x.ZoneID === ZoneID)
      //   .packs.push(newPack);

      this.productsDetails.zones.forEach((element) => {
        let lastPack = element.packs[element.packs.length - 1];
        delete lastPack.PackStimulusid;
        delete lastPack.Id;
        let newPack = {
          ...lastPack,
          FriendlyName: `Test ${element.packs.length}`,
        };
        newPack.ImageURL = "";

        element.packs.push(newPack);
      });
    },
    setProductInfo(data, rowIndex, itemIndex) {
      this.productsDetails.zones[rowIndex].packs[itemIndex] = Object.assign(
        this.productsDetails.zones[rowIndex].packs[itemIndex],
        data
      );
      this.$emit("setProductsDetails", this.productsDetails);
    },
    deleteProductInfo(itemIndex) {
      // this.productsDetails.zones[rowIndex].packs.splice(itemIndex, 1);
      // this.$emit("setProductsDetails", this.productsDetails);

      this.activeColumn = null;
      this.productsDetails.zones.forEach((item) => {
        item.packs.splice(itemIndex, 1);
      });
      this.$emit("setProductsDetails", this.productsDetails);
    },
    addDefaultZoneData() {
      this.chosenProductsList.forEach((item, i) => {
        let pack = {
          ...item,
          FriendlyName: "Control",
          ImageURL: "",
        };

        let packsLength =
          this.productsDetails.zones.length > 0
            ? this.productsDetails.zones[0].packs.length
            : 0;

        // if (data !== undefined) {
        //   let zone = data.find((area) => area.UPC == item.UPC);

        //   if (zone !== undefined) {
        //     pack.ProductName = zone.productName;
        //     pack.Weight = zone.weight;
        //     pack.Price = zone.Price;
        //     pack.UPC = zone.upc;
        //     pack.FacingNum = zone.facingNum;
        //     pack.ImageURL = zone.popupURL;
        //   }
        // }

        let product = {
          ZoneID: item.UPC,
          AreaId: item.UPC,
          productUPC: item.UPC,
          deepDive: i === 0 ? true : false,
          packs: [pack],
        };
        const index = this.productsDetails.zones.findIndex(
          (x) => x.ZoneID == item.UPC
        );
        if (index === -1) {
          for (let i = 1; i < packsLength; i++) {
            product.packs.push({
              ImageURL: "",
              ProductName: product.packs[0].ProductName,
              Price: product.packs[0].Price,
              Weight: product.packs[0].Weight,
              UPC: product.packs[0].UPC,
              FriendlyName: `Test ${i}`,
            });
          }
          this.productsDetails.zones.push(product);
        }
        // const index = this.productsDetails.zones.findIndex(
        //   (x) => x.UPC == item.UPC
        // );
        // if (index === -1) {
        //   for (let i = 1; i < packsLength; i++) {
        //     product.packs.push({
        //       ImageURL: "",
        //       ProductName: product.packs[0].ProductName,
        //       Price: product.packs[0].Price,
        //       Weight: product.packs[0].Weight,
        //       UPC: product.packs[0].UPC,
        //       FriendlyName: `Test ${i}`,
        //       FacingNum: product.packs[0].FacingNum,
        //       Notes: "",
        //     });
        //   }
        //   this.productsDetails.zones.push(product);
        // }
        // if (this.productsDetails.zones.length === 1) {
        //   this.productsDetails.zones[0].deepDive = true;
        // }
      });
    },
    getCSVZoneData() {
      // const randomIndetifier = Math.random();
      // mutations.setCSVZoneLoader(randomIndetifier);
      // const getCSVZoneData = new Promise((resolve) => {
      //   wsUtils.GetCSVZoneData(
      //     { shelfID: this.shelfID, AreaID: areaIds.toString() },
      //     resolve
      //   );
      // });
      // getCSVZoneData.then((data) => {
      //   this.addDefaultZoneData(data);
      //   mutations.setCSVZoneLoader(randomIndetifier);
      // });
      this.addDefaultZoneData();
    },
  },

  updated() {
    if (this.chosenProductsList.length > 0) {
      this.addDefaultZoneData();

      this.productsDetails = Object.assign({}, this.productsDetailsInfo);
      // let areaIds = [];
      // this.chosenProductsList.forEach((item) => {
      //   areaIds.push(item.UPC);
      // });
      // this.getCSVZoneData(areaIds);

      this.productsDetails.zones.forEach((item) => {
        const index1 = this.chosenProductsList.findIndex(
          (x) => x.UPC == item.ZoneID
        );
        if (index1 === -1) {
          this.productsDetails.zones.splice(
            this.productsDetails.zones.findIndex(
              (el) => el.ZoneID === item.ZoneID
            ),
            1
          );
        }
      });
    }
    this.productsDetails.SurveyId = this.surveyID;
    this.productsDetails.TemplateShelfID = this.shelfID;
    this.productsDetails.Brand = this.activeBrand;
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  },
};
</script>

<style lang="scss" scoped>
.chosen-products-list {
  padding-bottom: 65px;
  &__items {
    width: 60%;
    @media screen and (max-width: 1180px) {
      width: 100%;
      padding-right: 50px;
    }
    @media screen and (max-width: 800px) {
      width: 290px;
    }
  }
  &__image {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      max-width: 100%;
      max-height: 80px;
      object-fit: contain;
    }
  }
  &__image-cta {
    font-size: 11px;
    position: relative;
    color: #1c7dd6;
    padding: 2px 5px;
    border-radius: 4px;
    font-weight: 500;
    margin-top: 6px;
    svg {
      margin-right: 1px;
    }
  }
  &__items-cta {
    margin: 20px 0 30px;
  }
  &__delete-test {
    position: absolute;
    top: -55px;
    right: 5px;
    color: #6a6a6a;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
  &__add-details {
    width: 94px;
    height: 100px;
    position: relative;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 5px;
    &:after {
      position: absolute;
      left: 50%;
      top: 8px;
      transform: translateX(-50%);
      content: "";
      width: 32px;
      height: 32px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 312.602 312.602' style='enable-background:new 0 0 312.602 312.602' xml:space='preserve'%3E%3Cpath style='fill:%23000002' d='M251.52 137.244c-3.966 0-7.889.38-11.738 1.134-1.756-47.268-40.758-85.181-88.448-85.181-43.856 0-80.964 32.449-87.474 75.106C28.501 129.167 0 158.201 0 193.764c0 36.106 29.374 65.48 65.48 65.48h54.782c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H65.48c-27.835 0-50.48-22.645-50.48-50.48 0-27.835 22.646-50.48 50.48-50.48 1.367 0 2.813.067 4.419.206l7.6.658.529-7.61c2.661-38.322 34.861-68.341 73.306-68.341 40.533 0 73.51 32.977 73.51 73.51 0 1.863-.089 3.855-.272 6.088l-.983 11.968 11.186-4.367a45.863 45.863 0 0 1 16.747-3.151c25.409 0 46.081 20.672 46.081 46.081 0 25.408-20.672 46.08-46.081 46.08-.668 0-20.608-.04-40.467-.08-19.714-.04-39.347-.08-39.999-.08-4.668 0-7.108-2.248-7.254-6.681v-80.959l8.139 9.667a7.497 7.497 0 0 0 10.567.907 7.498 7.498 0 0 0 .907-10.567l-18.037-21.427c-2.272-2.699-5.537-4.247-8.958-4.247-3.421 0-6.686 1.548-8.957 4.247l-18.037 21.427a7.498 7.498 0 0 0 .907 10.567 7.466 7.466 0 0 0 4.826 1.763 7.482 7.482 0 0 0 5.741-2.67l7.901-9.386v80.751c0 8.686 5.927 21.607 22.254 21.607.652 0 20.27.04 39.968.079 19.874.041 39.829.081 40.498.081 33.681 0 61.081-27.4 61.081-61.08 0-33.681-27.401-61.081-61.082-61.081z'/%3E%3C/svg%3E")
        center center no-repeat;
      z-index: 1;
      pointer-events: none;
    }
  }
  &__add-details-info {
    display: block;
    max-width: 100px;
    margin: 0 auto;
    text-align: center;
    font-size: 11px;
    color: #3d3d3d;
    line-height: 14px;
    margin-top: 32px;
  }
  .active-col-bcg {
    background: #f2f2f2 !important;
  }
}

::v-deep .custom-table {
  thead {
    border-color: #fff;
  }
  tbody tr {
    border-color: #fff;
    td {
      &:nth-child(2) {
        width: 100px;
      }
      &:nth-child(3) {
        min-width: 230px;
      }
    }
  }
}

.image-requirements {
  padding: 10px 0;
  &__row {
    margin-bottom: 33px;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    > p {
      color: black;
      font-size: 16px;
      font-weight: 300;
    }

    h2 {
      font-size: 15px;
      font-weight: 400;
      margin: 25px 0 10px;
    }
  }
  &__item {
    height: 100%;
    border: 1px solid #00d1b2;
    display: flex;
    flex-direction: column;
    p {
      text-align: center;
      padding: 10px;
      line-height: 18px;
      font-size: 13px;
      flex: 1;
    }
    &--red {
      border-color: #ff3860;
      .image-requirements__item-category,
      .image-requirements__item-dimensions {
        background: #ff3860;
      }
    }
    &--transparent-info {
      background: #d0d0d0;
      border-width: 0;
      padding-bottom: 10px;
      .image-requirements__item-image {
        padding-bottom: 56.7%;
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
    }
  }
  &__item-category {
    background: #00d1b2;
    color: #fff;
    text-align: center;
    padding: 3px;
    margin-bottom: 10px;
  }
  &__item-image {
    padding-bottom: 100%;
    position: relative;
    img {
      max-width: 100%;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
  }
  &__item-dimensions {
    background: #00d1b2;
    color: #fff;
    text-align: center;
    span {
      padding: 5px;
      display: flex;
      align-items: center;
      font-size: 13px;
      justify-content: center;
      &:first-child {
        border-right: 1px solid #c3ede4;
      }
    }
  }
}
</style>
