var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-bar"},[(_vm.loading)?_vm._l((5),function(item){return _c('div',{key:item,staticClass:"progress-bar__item progress-bar__item--loader"},[_c('Skeleton',{attrs:{"borderRadius":24}})],1)}):_vm._l((_vm.steps),function(item,index){return _c('div',{key:item,staticClass:"progress-bar__item",class:{
      'is-passed': index + 1 < _vm.activeStep || _vm.editMode,
      'is-active':
        index + 1 ===
        (!_vm.activeDeepDiveStep && _vm.activeStep === 5 ? 4 : _vm.activeStep),
    },on:{"click":function($event){index + 1 < _vm.activeStep || _vm.editMode
        ? _vm.$emit('activeStep', index + 1)
        : null}}},[_c('i',[_vm._v(_vm._s(index + 1))]),_c('span',[_vm._v(_vm._s(item))]),_c('div',{staticClass:"progress-bar__item-tooltip"},[_vm._v(_vm._s(item))])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }