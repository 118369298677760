<template>
  <div class="videoAds">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item
          ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
        >
        <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
        <a-breadcrumb-item>Pack Test</a-breadcrumb-item>
      </template>
    </a-breadcrumb>
    <div class="authoring">
      <ProgressBar
        :stepIndex="stepIndex"
        stepsType="pack"
        :surveyID="surveyID"
      />
      <section class="stepAuthor stimuli">
        <StimuliProgressBar
          v-if="!loadingShelfList || activeStep === 3"
          :activeStep="activeStep"
          :activeDeepDiveStep="activeDeepDiveStep"
          @activeStep="setActiveStep"
        ></StimuliProgressBar>
        <Loader
          text="Loading..."
          :paddingTop="150"
          :paddingBottom="80"
          v-if="activeStep === 0"
        ></Loader>
        <ShelfList
          :shelfList="shelfLibraryInfo"
          :loading="loadingShelfList"
          :country="surveyInfo.country"
          :category="surveyInfo.category"
          :activeShelfId="activeShelf !== null ? activeShelf.shelfID : null"
          v-if="activeStep !== 0"
          v-show="activeStep === 1"
          @ShelfData="setActiveShelf"
          @nextStep="activeStep = 2"
          @send-help-request="sendHelpRequest"
        ></ShelfList>

        <ProductsList
          v-if="activeStep !== 0"
          v-show="activeStep === 2"
          :activeShelf="activeShelf"
          :selectedProductsKeys="selectedRowKeys"
          :selectedProducts="chosenProducts"
          :shelfImageId="shelfImageId"
          :currentBrand="activeBrand"
          :currentShelfId="currentShelfId"
          :surveyBrand="surveyInfo.brand"
          @brand="activeBrand = $event"
          @prevStep="activeStep = 1"
          @nextStep="activeStep = 3"
          @products-details="chosenProducts = $event"
          @brandChanged="brandChanged"
          @brand-list="brandList = $event"
        ></ProductsList>

        <ChosenProductsList
          :chosenProductsList="chosenProducts"
          :unEncryptSurveyId="unEncryptSurveyId"
          :surveyID="surveyID"
          :shelfID="
            activeShelf ? activeShelf.shelfID : productsDetails.TemplateShelfID
          "
          :activeBrand="activeBrand"
          :activeShelf="activeShelf"
          :activeDeepDiveStep="activeDeepDiveStep"
          :activeStep="activeStep"
          v-show="activeStep === 3"
          @setProductsDetails="setProductsDetails"
          :productsDetailsInfo="productsDetails"
          :editableSurvey="editableSurvey"
          @editShelf="activeStep = 1"
          @prevStep="activeStep = 2"
          @nextStep="
            activeDeepDiveStep
              ? (activeStep = 4)
              : ((activeStep = 5), setDeepDiveProducts())
          "
        ></ChosenProductsList>

        <DeepDive
          v-if="activeStep === 4"
          :chosenProductsList="chosenProducts"
          :productsDetailsInfo="productsDetails"
          @setDeepDive="setDeepDive"
          @prevStep="activeStep = 3"
          @nextStep="(activeStep = 5), setDeepDiveProducts()"
        ></DeepDive>

        <CompetitiveBenchmark
          v-if="activeStep !== 0 && activeStep !== 1"
          v-show="activeStep === 5"
          :deepDiveProducts="deepDiveProducts"
          :brandList="brandList"
          :shelfImageId="activeShelf.imageID || shelfImageId"
          :shelfId="activeShelf.shelfID"
          :activeBrand="activeBrand"
          :activeStep="activeStep"
          :productsDetailsInfo="productsDetails"
          :editableSurvey="editableSurvey"
          :activeDeepDiveStep="activeDeepDiveStep"
          :chosenProducts="chosenProducts"
          @prevStep="activeDeepDiveStep ? (activeStep = 4) : (activeStep = 3)"
          @competitive-products="setCompetitiveProducts"
          @disable-next-step="nextDisable = $event"
          @enable-deep-dive="enableDeepDive"
        ></CompetitiveBenchmark>
      </section>
      <StepNaviBar
        :stepIndex="stepIndex"
        :nextDisable="nextBtnDisable"
        :conciergeAssistance="true"
        stepsType="pack"
        prevBtnTooltip="Back to General Settings"
        nextBtnTooltip="Go to Brand Strategy"
        @nextHandler="nextStep"
        @prevHandler="prevStep"
      />
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import ShelfList from "@/components/packTestSurvey/ShelfList.vue";
import DeepDive from "@/components/packTestSurvey/DeepDive.vue";
import CompetitiveBenchmark from "@/components/packTestSurvey/CompetitiveBenchmark.vue";
import StimuliProgressBar from "@/components/packTestSurvey/ProgressBar.vue";
import ProductsList from "@/components/packTestSurvey/ProductsList.vue";
import ChosenProductsList from "@/components/packTestSurvey/ChosenProductsList.vue";
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
import _ from "lodash";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";

export default {
  name: "Stimuli",
  components: {
    ProgressBar,
    StepNaviBar,
    ShelfList,
    StimuliProgressBar,
    ProductsList,
    ChosenProductsList,
    Loader,
    DeepDive,
    CompetitiveBenchmark,
  },
  mixins: [surveyPriceMixin],
  props: {
    SurveyTypeId: Number,
  },
  data() {
    return {
      projectID: undefined,
      client: {},
      stepIndex: 2,
      surveyID: "0",
      nextDisable: true,
      user: null,
      shelfLibraryInfo: [],
      surveyInfo: {
        country: "",
        category: "",
        brand: "",
      },
      loadingShelfList: true,
      activeShelf: null,
      activeStep: 0,
      chosenProducts: [],
      unEncryptSurveyId: null,
      activeBrand: null,
      productsDetails: {
        SurveyId: null,
        TemplateShelfID: null,
        Brand: null,
        zones: [],
      },
      selectedRowKeys: [],
      editableSurvey: false,
      shelfImageId: null,
      currentBrand: null,
      currentShelfId: null,
      progressBarEditMode: false,
      deepDiveProducts: [],
      brandList: [],
      activeDeepDiveStep: false,
      ssoLogin: null,
      packDetailsAdded: false,
    };
  },
  computed: {
    nextBtnDisable() {
      let checkDeepDive = this.productsDetails.zones.find(
        (item) => item.deepDive === true
      );
      return checkDeepDive === undefined ||
        this.nextDisable ||
        this.activeStep !== 5 ||
        this.chosenProducts.length === 0
        ? true
        : false;
    },
  },
  watch: {
    chosenProducts() {
      this.chosenProducts.length > 0 && this.editableSurvey === true
        ? (this.progressBarEditMode = true)
        : (this.progressBarEditMode = false);
      this.chosenProducts.length > 1
        ? (this.activeDeepDiveStep = true)
        : (this.activeDeepDiveStep = false);
      if (this.chosenProducts.length === 0) {
        this.productsDetails.zones = [];
        this.priceCalculation();
      }
    },
    "productsDetails.zones": {
      immediate: true,
      handler() {
        this.priceCalculation();
      },
      deep: true,
    },
  },
  methods: {
    reset() {
      this.activeStep = 0;
      this.activeBrand = null;
      this.chosenProducts = [];
      this.productsDetails = {
        SurveyId: null,
        TemplateShelfID: null,
        Brand: null,
        zones: [],
      };
      this.selectedRowKeys = [];
      this.activeShelf = null;
    },
    findEmptyData(object) {
      let result;
      if (!object || typeof object !== "object") return;

      if (Object.prototype.hasOwnProperty.call(object, "customProducts")) {
        delete object.customProducts;
      }
      if (Object.prototype.hasOwnProperty.call(object, "customURLs")) {
        delete object.customURLs;
      }
      if (Object.prototype.hasOwnProperty.call(object, "currency")) {
        delete object.currency;
      }

      if (object.zones !== undefined) {
        object.zones.forEach((item) => {
          _.unset(item, ["productUPC"]);
          if (item.CompetitiveProduct !== undefined) {
            if (
              item.CompetitiveProduct.areaID === 0 &&
              item.CompetitiveProduct.ZoneID === 0
            ) {
              _.unset(item, ["CompetitiveProduct"]);
            } else {
              if (item.CompetitiveProduct.Weight === "") {
                _.unset(item.CompetitiveProduct, ["Weight"]);
              }
              if (item.CompetitiveProduct.weight === "") {
                _.unset(item.CompetitiveProduct, ["weight"]);
              }
            }
            _.unset(item.CompetitiveProduct, ["competitiveUPC"]);
          }

          item.packs.forEach((pack) => {
            if (pack.Notes === "") {
              _.unset(pack, ["Notes"]);
            }
            if (pack.Weight === "") {
              _.unset(pack, ["Weight"]);
            }
          });
        });
      }
      Object.values(object).some((v) => {
        if (v === "") return (result = object);
        return (result = this.findEmptyData(v));
      });

      return result;
    },
    nextStep() {
      this.setSurveySummaryData();
      this.productsDetails.SurveyId = this.surveyID;
      this.productsDetails.TemplateShelfID = this.activeShelf.shelfID;
      this.productsDetails.Brand = this.activeBrand;
      let obj = Object.assign({}, this.productsDetails);
      if (this.findEmptyData(obj) !== undefined) {
        let _this = this;
        this.$confirm({
          title: "Next step",
          content: () => (
            <div>
              Are you sure you want to proceed to the next step? You didn't add
              all pack details, so you will not be able to proceed with the
              Survey.
            </div>
          ),
          onCancel() {
            _this.setPackDetails();
            _this.$router.push({
              name: "communication-objective",
              params: { id: _this.surveyID },
            });
            jsUtils.setRunSurveyMode(false);
          },
          onOk() {
            _this.activeStep = 3;
          },
          okText: "Add pack details",
          cancelText: "Next step",
        });
      } else {
        this.setPackDetails();
        this.$router.push({
          name: "communication-objective",
          params: { id: this.surveyID },
        });
        jsUtils.setRunSurveyMode(true);
      }
    },
    shelfLibrary() {
      const getShelfLibrary = new Promise((resolve) => {
        wsUtils.GetShelfLibraryV2(
          {
            userId: this.user.EncrypteduserID,
            category: this.surveyInfo.categoryId,
            country: this.surveyInfo.country,
          },
          resolve
        );
      });

      getShelfLibrary.then((data) => {
        this.setShelfLibraryInfo(data);
        setTimeout(() => {
          this.loadingShelfList = false;
        }, 1000);

        if (this.shelfLibraryInfo.length > 0) {
          this.packDetails();
        } else {
          this.activeStep = 1;
        }
      });
    },
    setShelfLibraryInfo(data) {
      data.forEach((element) => {
        element.thumbImageURL = element.imageURL.replace(
          /(\.[\w\d_-]+)$/i,
          "_thumb$1"
        );
        element.Name = element.Name.split(":")[0];
      });
      this.shelfLibraryInfo = data;
    },
    setActiveShelf(data) {
      let activeShelfId = this.activeShelf ? this.activeShelf.shelfID : "";
      if (this.chosenProducts.length > 0 && data.shelfID !== activeShelfId) {
        let _this = this;
        this.$confirm({
          title: "Change shelf",
          content: () => (
            <div>
              Are you sure you want to change a shelf? If you change it, all
              products you already selected will be deleted.
            </div>
          ),
          onCancel() {
            _this.activeBrand = null;
            _this.activeShelf = data;
            _this.activeStep = 2;
            _this.chosenProducts = [];
          },
          okText: "No",
          cancelText: "Yes, change it",
        });
      } else {
        this.activeShelf = data;
        this.activeStep = 2;
      }
    },
    setActiveStep(step) {
      this.activeStep = step;
    },
    setProductsDetails(data) {
      this.productsDetails = Object.assign(this.productsDetails, data);
    },
    setPackDetails() {
      if (this.editableSurvey) {
        this.updatePack();
      } else {
        this.savePack();
      }
    },
    savePack() {
      this.productsDetails.userId = this.user.EncrypteduserID;
      const savePackDetails = new Promise((resolve) => {
        wsUtils.SavePackDetails(
          { SurveyId: this.surveyID, ...this.productsDetails },
          resolve
        );
      });

      savePackDetails.then(() => {
        jsUtils.setSurveysApiTrigger(true);
      });
    },
    updatePack() {
      delete this.productsDetails.__type;
      this.productsDetails.zones.forEach((item) => {
        if (
          (item.CompetitiveAreaID === undefined ||
            item.CompetitiveZoneID === undefined) &&
          item.CompetitiveProduct !== undefined
        ) {
          item.CompetitiveAreaID = item.CompetitiveProduct.areaID;
          item.CompetitiveZoneID = item.CompetitiveProduct.ZoneID;
        }
        delete item.CompetitiveProduct;
      });
      this.productsDetails.userId = this.user.EncrypteduserID;
      const updatePackDetails = new Promise((resolve) => {
        wsUtils.UpdatePackDetails(this.productsDetails, resolve);
      });

      updatePackDetails.then(() => {
        this.editableSurvey = false;
        jsUtils.setSurveysApiTrigger(true);
      });
    },
    packDetails() {
      const getPackDetails = new Promise((resolve) => {
        wsUtils.GetPackDetails(
          {
            SurveyId: this.surveyID,
          },
          resolve
        );
      });

      getPackDetails.then((data) => {
        if (data.length > 0) {
          this.editableSurvey = true;
          this.nextDisable = false;
          this.progressBarEditMode = true;
          delete data[0].customProducts;
          delete data[0].customURLs;
          this.productsDetails = Object.assign({}, data[0]);

          data[0].zones.forEach((item) => {
            this.selectedRowKeys.push(item.AreaId.toString());
          });
          this.getShelfInfo();

          if (data.Brand === this.surveyInfo.brand) {
            this.activeStep = 3;
          } else {
            this.activeStep = 2;
          }
        } else {
          this.activeStep = 1;
          this.editableSurvey = false;
          this.progressBarEditMode = false;
        }
        this.packDetailsAdded = true;
      });
    },
    getShelfInfo() {
      let shelf = this.shelfLibraryInfo.find((obj) => {
        return obj.shelfID === this.productsDetails.TemplateShelfID;
      });
      this.activeShelf = shelf;
      this.shelfImageId = shelf.imageID;
      this.activeBrand = this.productsDetails.Brand;
      this.currentShelfId = this.productsDetails.TemplateShelfID;
    },
    brandChanged(data) {
      if (data.brand !== null) {
        this.productsDetails.Brand = data.brand;
      }
      if (data.event === "click" || data.event === "custom") {
        this.productsDetails.zones = [];
        this.selectedRowKeys = [];
      }
    },
    prevStep() {
      if (
        this.ssoLogin !== null &&
        this.ssoLogin.action !== "" &&
        this.ssoLogin.pgprojectid !== ""
      ) {
        window.open(
          `https://d11qiq41szmpd8.cloudfront.net/${this.ssoLogin.pgprojectid}`,
          "_self",
          "noreferrer"
        );
      } else {
        this.$router.push({
          name: "pack-test-setting",
          params: { id: this.surveyID },
        });
      }
    },
    setDeepDive(data) {
      let deepDiveProducts = _.filter(this.productsDetails.zones, {
        deepDive: true,
      });

      if (deepDiveProducts.length === 5 && data.deepDive === "yes") {
        this.$notification["warning"]({
          message: `Oops`,
          description: "You reached the limit of 5 deep dive products.",
          placement: "bottomLeft",
          duration: 5,
        });
      } else {
        this.productsDetails.zones[data.index].deepDive =
          data.deepDive === "yes" ? true : false;

        if (this.productsDetails.zones[data.index].deepDive === false) {
          delete this.productsDetails.zones[data.index].CompetitiveAreaID;
          delete this.productsDetails.zones[data.index].CompetitiveZoneID;
        }
        this.setDeepDiveProducts();
      }

      let checkDeepDive = this.productsDetails.zones.find(
        (item) => item.deepDive === true
      );

      if (checkDeepDive === undefined) {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "You have to select one product for 'deep dive' at least.",
          placement: "bottomLeft",
          duration: 5,
        });
      }
    },
    setSurveySummaryData() {
      let deepDiveProductsName = [];
      let competitorDeepDiveProductsName = [];
      let deepDiveProducts = this.productsDetails.zones.filter(
        (item) => item.deepDive === true
      );
      let competitorDeepDiveProducts = this.productsDetails.zones.filter(
        (item) => item.CompetitiveDeepDive === true
      );
      const uniqueFriendlyNames = [
        ...new Set(
          this.productsDetails.zones[0].packs.map(
            (product) => product.FriendlyName
          )
        ),
      ];

      deepDiveProducts.forEach((item) => {
        let deepDiveProduct = this.chosenProducts.find(
          (product) => product.areaID == item.AreaId
        ).productName;
        deepDiveProductsName.push(deepDiveProduct);
      });

      competitorDeepDiveProducts.forEach((item) => {
        competitorDeepDiveProductsName.push(
          item.CompetitiveProduct?.productName ||
            item.CompetitiveProduct?.ProductName
        );
      });

      let uniqueCompetitorDeepDiveProducts = [
        ...new Set(competitorDeepDiveProductsName),
      ];

      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({
        ...currentSummaryData,
        deepDiveProductsName: [
          ...deepDiveProductsName,
          ...uniqueCompetitorDeepDiveProducts,
        ],
        shelfRetailer: this.activeShelf.Retailer || "",
        activeShelf: this.activeShelf,
        chosenProducts: this.chosenProducts,
        legsName: uniqueFriendlyNames,
      });
    },
    setDeepDiveProducts() {
      this.deepDiveProducts = [];
      let deepDiveProducts = this.productsDetails.zones.filter(
        (item) => item.deepDive === true
      );

      this.chosenProducts.forEach((item) => {
        item.deepDive = false;
      });

      deepDiveProducts.forEach((item) => {
        let deepDiveProduct = this.chosenProducts.find(
          (product) => product.areaID == item.AreaId
        );
        deepDiveProduct.deepDive = true;
        this.deepDiveProducts.push(deepDiveProduct);
      });
    },
    setCompetitiveProducts(data) {
      let productsList = Object.keys(data).map((key) => {
        return data[key];
      });
      productsList.forEach((item) => {
        let product = this.productsDetails.zones.find(
          (x) => x.ZoneID === item.parentZoneId
        );
        if (product !== undefined) {
          product.CompetitiveAreaID = item.areaID;
          product.CompetitiveZoneID = item.zoneId;
          product.CompetitiveProduct = item;
          product.CompetitiveDeepDive =
            item.CompetitiveDeepDive !== undefined
              ? item.CompetitiveDeepDive
              : false;
        }
      });
    },
    enableDeepDive() {
      const findCompetitiveDeepDive = _.filter(
        this.productsDetails.zones,
        function (o) {
          return o.CompetitiveDeepDive === true;
        }
      );
      let currentPackTestPriceData = jsUtils.getPackTestPrice();
      jsUtils.setPackTestPrice({
        ...currentPackTestPriceData,
        compDeepDive: findCompetitiveDeepDive.length,
      });
      this.getPackTestTotalPrice();
    },
    sendHelpRequest(message) {
      const packtestHelpRequest = new Promise((resolve) => {
        wsUtils.PacktestHelpRequest(
          {
            Message: message,
            UserId: this.user.EncrypteduserID,
            SurveyId: this.surveyID,
          },
          resolve
        );
      });
      packtestHelpRequest.then((data) => {
        const notificationType = data.success ? "success" : "error";
        this.$notification[notificationType]({
          message: data.success ? "Well done!" : "Error!",
          description: data.success
            ? "Your request was successful sent."
            : "Something went wrong, please try again or contact our support.",
          placement: "bottomLeft",
          duration: 6,
        });
      });
    },
    priceCalculation() {
      let totalLegs = this.productsDetails.zones
        .map((item) => item.packs)
        .map((k) => k.filter((e) => e.FriendlyName !== "Control"))
        .map((item) => item.length)
        .reduce((prev, curr) => prev + curr, 0);
      const setTotalLegs =
        totalLegs !== 0 ? totalLegs / this.productsDetails.zones.length + 1 : 1;

      const totalDeepDive = this.productsDetails.zones
        .map((item) => item.deepDive)
        .reduce((prev, curr) => prev + curr, 0);
      const setTotalDeepDive = totalDeepDive !== 0 ? totalDeepDive : 1;
      let currentPackTestPriceData = jsUtils.getPackTestPrice();

      const findCompetitiveDeepDive = _.filter(
        this.productsDetails.zones,
        function (o) {
          return o.CompetitiveDeepDive === true;
        }
      );
      if (
        currentPackTestPriceData !== undefined &&
        (setTotalLegs !== currentPackTestPriceData.legs ||
          setTotalDeepDive !== currentPackTestPriceData.deepDive ||
          findCompetitiveDeepDive.length !==
            currentPackTestPriceData.compDeepDive) &&
        this.packDetailsAdded
      ) {
        jsUtils.setPackTestPrice({
          ...currentPackTestPriceData,
          legs: setTotalLegs,
          deepDive: setTotalDeepDive,
          compDeepDive: findCompetitiveDeepDive.length,
        });
        this.getPackTestTotalPrice();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== "communication-objective" &&
      !this.editableSurvey &&
      this.activeShelf !== null &&
      to.name !== "login" &&
      to.name !== "samlssos-singout"
    ) {
      this.$confirm({
        title: "Discard draft",
        content: () => (
          <div>
            If you leave the page this Stimuli draft will be discarded and you
            will lost progress.
          </div>
        ),
        okText: "Continue authoring",
        cancelText: "Discard Stimuli draft",
        onCancel() {
          next();
        },
      });
    } else {
      next();
    }
  },
  mounted() {
    this.client = jsUtils.getCurClient();
  },
  activated() {
    this.packDetailsAdded = false;
    this.reset();
    this.surveyInfo = jsUtils.getSurveySummaryData();
    this.surveyID = this.$route.params.id;
    this.unEncryptSurveyId = jsUtils.getSurveyUnEncryptId();
    this.user = jsUtils.getUserInfo();
    this.shelfLibrary();
    let ssoData = jsUtils.readCookie("sso-login");
    let SAMLSSOUser = jsUtils.readCookie("SAMLSSOSignedIn");
    if (ssoData !== "" && SAMLSSOUser) {
      this.ssoLogin = JSON.parse(ssoData);
    }
  },
};
</script>

<style lang="scss" scoped>
.stimuli {
  display: block;
  padding: 50px 77px;
  @media screen and (max-width: 980px) {
    padding: 30px 20px;
  }
}
</style>
