<template>
  <div class="product-list">
    <StimuliTitle
      title="Please select all of the products that will be part of this packaging test."
    ></StimuliTitle>

    <div
      class="flex items-center justify-between pt-0 management-system__tools"
    >
      <Skeleton v-if="loadingFilters"></Skeleton>
      <div class="flex items-center management-system__filters">
        <div
          class="management-system__filters-item"
          v-if="showBrandDropdown && !loadingBrandList"
        >
          <label>Brand</label>
          <a-select
            class="fullWidth"
            option-filter-prop="children"
            show-search
            placeholder="Please select"
            :key="Math.random()"
            @change="getProducts('click', $event)"
            :default-value="defaultBrand || undefined"
          >
            <a-select-option
              v-for="item in brandList"
              :key="item.BrandName"
              :value="item.BrandName"
            >
              {{ item.BrandName }}
            </a-select-option>
          </a-select>
        </div>
        <div
          v-if="!showBrandDropdown && !loadingBrandList"
          class="product-list__selected-brand mr-10"
        >
          <strong>Selected brand:</strong> {{ currentBrand }}
          <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
            <template slot="title">
              <span>Change selected brand</span>
            </template>
            <font-awesome-icon
              icon="edit"
              class="cursor-pointer"
              @click="showBrandDropdown = true"
            />
          </a-tooltip>
        </div>
        <!-- <a-button
          class="management-system__filters-search-btn"
          type="primary"
          @click="getProducts('click')"
          v-if="showBrandDropdown"
        >
          <div class="flex" v-if="loading">
            <i class="circle-loader"></i>
          </div>
          <span v-else>Find Products</span>
        </a-button> -->
        <div
          class="
            management-system__filters-item
            management-system__filters-item--search
          "
          :class="{
            'management-system__filters-item--search-first-field':
              !showBrandDropdown,
          }"
          v-if="
            brandProductsList.length && viewType === 'list' && !loadingBrandList
          "
        >
          <label>Search products</label>
          <div class="position-r">
            <font-awesome-icon icon="search" />
            <a-input
              :class="{ active: searchParametars != '' }"
              v-model="searchParametars"
              placeholder="Insert product name"
            >
              <a-icon
                slot="addonAfter"
                theme="filled"
                type="close-circle"
                v-show="searchParametars != ''"
                @click="searchParametars = ''"
            /></a-input>
          </div>
        </div>
      </div>
      <p
        class="management-system__tools-counter"
        v-if="brandProductsList.length"
      >
        {{ selectedRowKeys.length }} selected product<span
          v-if="selectedRowKeys.length !== 1"
          >s</span
        >
        <span
          class="management-system__tools-counter-preview"
          v-if="selectedProducts.length > 0"
          @click="productsQuickPreview = !productsQuickPreview"
          >{{ productsQuickPreviewTitle }}</span
        >
      </p>
      <div
        class="flex management-system__view-cta"
        v-if="brandProductsList.length"
      >
        <div
          class="flex items-center management-system__view-cta-item"
          :class="{ 'is-active': viewType === 'list' }"
          @click="viewType = 'list'"
        >
          <i class="flex items-center justify-center"
            ><font-awesome-icon icon="list"
          /></i>
          <span>List view</span>
        </div>
        <div
          class="flex items-center management-system__view-cta-item"
          @click="viewType = 'shelf'"
          :class="{ 'is-active': viewType === 'shelf' }"
        >
          <i class="flex items-center justify-center"
            ><font-awesome-icon icon="store"
          /></i>
          <span>Shelf view</span>
        </div>
      </div>
      <p class="management-system__shelf-info" v-if="viewType === 'shelf'">
        Please click on, then select the product(s) on shelf that you are
        testing.
      </p>
    </div>
    <div
      class="product-list__selected flex flex-wrap"
      v-if="productsQuickPreview && selectedProducts.length > 0"
    >
      <SelectedProductItem
        v-for="product in selectedProducts"
        :key="product.zoneId"
        :product="product"
        @remove-product="selectRow(product)"
      ></SelectedProductItem>
    </div>

    <div class="management-system__table" v-if="viewType === 'list'">
      <a-table
        tableLayout="auto"
        class="custom-table"
        :columns="columns"
        :row-key="(record) => record.areaID"
        :data-source="searchProductList"
        :loading="loading || getCSVZoneLoader"
        :pagination="pagination"
        @change="handleTableChange"
        v-if="brandProductsList.length"
        :row-class-name="
          (_record) => (_record.selected ? 'row-selected' : null)
        "
        :row-selection="rowSelection"
        :custom-row="customRow"
        :class="{ 'disable-select': selectedRowKeys.length === 3 }"
      >
        <div
          slot="Image"
          class="product-list__image image-loading"
          slot-scope="record"
        >
          <img
            :src="record.ImageURL"
            @load="
              $event.target.parentNode.classList.toggle('image-loading--loaded')
            "
          />
        </div>
      </a-table>

      <DataInfo
        v-else
        :paddingTop="40"
        :paddingBottom="40"
        :text="
          !noBrandProducts
            ? 'Select a brand to see a list of products...'
            : 'There are no products for the selected brand...'
        "
      ></DataInfo>
    </div>

    <ShelfPreview
      :shelfID="activeShelf.shelfID"
      v-if="viewType === 'shelf'"
      :brandProductsList="brandProductsList"
      :key="previewShelfKey"
      :activeBrand="currentBrand"
      mode="edit"
      :selectedProducts="selectedRowKeys"
      @selectedEditableProducts="selectedEditableProducts"
    ></ShelfPreview>

    <div class="flex justify-between stimuli-nav">
      <a-button @click="prevStep"
        ><font-awesome-icon icon="arrow-left" /> Select Shelf</a-button
      >
      <a-button
        type="primary"
        :disabled="selectedRowKeys.length === 0"
        @click="nextStep"
        >Upload Test Images <font-awesome-icon icon="arrow-right"
      /></a-button>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import ShelfPreview from "@/components/general/ShelfPreview.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import DataInfo from "@/components/general/DataInfo.vue";
import SelectedProductItem from "@/components/packTestSurvey/SelectedProductItem.vue";
import Skeleton from "@/components/general/Skeleton.vue";
import _ from "lodash";
import { store } from "@/utils/store.js";

const columns = [
  {
    title: "",
    scopedSlots: { customRender: "Image" },
  },
  {
    title: "Product",
    dataIndex: "productName",
  },
  {
    title: "Weight",
    dataIndex: "weight",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
];

export default {
  name: "ProductList",
  components: {
    StimuliTitle,
    ShelfPreview,
    DataInfo,
    SelectedProductItem,
    Skeleton,
  },
  props: {
    activeShelf: {
      type: Object,
    },
    selectedProductsKeys: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedProducts: {
      type: Array,
      default() {
        return [];
      },
    },
    shelfImageId: {
      type: Number,
    },
    currentBrand: {
      type: String,
    },
    currentShelfId: {
      type: Number,
    },
    surveyBrand: {
      type: String,
    },
  },
  data() {
    return {
      brandList: [],
      brandProductsList: [],
      chosenBrand: null,
      columns,
      pagination: {},
      selectedRowKeys: [],
      viewType: "list",
      previewShelfKey: null,
      noBrandProducts: false,
      searchParametars: "",
      loading: false,
      productsQuickPreview: false,
      loadingFilters: true,
      showBrandDropdown: false,
      loadingBrandList: false,
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
      };
    },
    searchProductList() {
      return this.brandProductsList.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every((v) => item.productName.toLowerCase().includes(v));
      });
    },
    defaultBrand() {
      let brand = this.chosenBrand || this.currentBrand;
      let index = this.brandList.findIndex(
        (brand) =>
          _.startCase(brand.BrandName) === _.startCase(this.surveyBrand)
      );
      if (index !== -1) {
        brand = this.chosenBrand || this.currentBrand || this.surveyBrand;
      }
      return _.startCase(brand);
    },
    productsQuickPreviewTitle() {
      return this.productsQuickPreview ? "Hide quick preview" : "Quick preview";
    },
    getCSVZoneLoader() {
      return store.csvZoneLoader.length > 0;
    },
  },
  watch: {
    activeShelf() {
      this.selectedRowKeys = [];
      this.getBrandList();
    },
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    getProducts(event, brand) {
      let index = this.brandList.findIndex(
        (brand) =>
          brand.BrandName.toUpperCase() === this.surveyBrand.toUpperCase()
      );
      if (
        (this.currentBrand !== undefined && this.currentBrand !== null) ||
        index !== -1
      ) {
        if (this.selectedRowKeys.length > 0) {
          let _this = this;
          this.$confirm({
            title: "Change brand",
            content: () => (
              <div>
                Are you sure you want to change a brand? If you change it, all
                products you already selected will be deleted.
              </div>
            ),
            onCancel() {
              if (brand !== undefined) {
                _this.setBrand(brand);
              }
              _this.changeBrand(event);
            },
            okText: "No",
            cancelText: "Yes, change it",
          });
        } else {
          if ((brand !== undefined && index !== -1) || event === "click") {
            this.setBrand(brand);
          }
          this.changeBrand(event);
        }
      } else if (event === "click" && brand === undefined) {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "Please select a brand from the dropdown list then click on the 'Search Products' button.",
          placement: "bottomLeft",
          duration: 5,
        });
      } else {
        if (event === "click" || (event !== "click" && index !== -1)) {
          this.setBrand(brand);
          this.changeBrand(event);
        }
      }
    },
    changeBrand(event) {
      this.loading = true;
      let index = this.brandList.findIndex(
        (brand) =>
          _.startCase(brand.BrandName) === _.startCase(this.surveyBrand)
      );
      if (index !== -1 && this.surveyBrand !== this.currentBrand) {
        this.setBrand(this.surveyBrand);
        event = "custom";
      } else {
        this.setBrand(
          this.chosenBrand || this.currentBrand || this.surveyBrand
        );
      }

      this.setBrand(this.chosenBrand || this.currentBrand || this.surveyBrand);
      const getShelfBrandProducts = new Promise((resolve) => {
        wsUtils.GetShelfBrandProducts(
          {
            ImageId: this.activeShelf
              ? this.activeShelf.imageID
              : this.shelfImageId,
            BrandName:
              this.chosenBrand || this.currentBrand || this.surveyBrand,
            shelfId: this.activeShelf
              ? this.activeShelf.shelfID
              : this.currentShelfId,
            excludedBrand: "",
          },
          resolve
        );
      });

      getShelfBrandProducts.then((data) => {
        this.brandProductsList = data;
        const pagination = { ...this.pagination };
        pagination.total = data.length;
        pagination.hideOnSinglePage = true;
        this.pagination = pagination;
        this.loading = false;
        this.previewShelfKey = Math.random();
        data.length
          ? (this.noBrandProducts = false)
          : (this.noBrandProducts = true);

        this.setProductsDetails();
      });
      this.selectedRowKeys = [];
      if (event !== "click" && event !== "custom") {
        this.selectedRowKeys = [...this.selectedProductsKeys];
      }

      this.$emit("brandChanged", { brand: this.chosenBrand, event: event });
    },
    getBrandList() {
      this.loadingBrandList = true;
      const getShelfBrand = new Promise((resolve) => {
        wsUtils.GetShelfBrand(
          {
            ImageId: this.activeShelf
              ? this.activeShelf.imageID
              : this.shelfImageId,
          },
          resolve
        );
      });

      getShelfBrand.then((data) => {
        this.brandList = data.map((item) => {
          return {
            BrandName: item.BrandName.toUpperCase(),
          };
        });
        this.$emit("brand-list", data);
        this.getProducts();

        let index = data.findIndex(
          (brand) =>
            brand.BrandName.toUpperCase() === this.surveyBrand.toUpperCase()
        );

        index === -1
          ? (this.showBrandDropdown = true)
          : (this.showBrandDropdown = false);

        this.loadingBrandList = false;
      });
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selectRow(record);
          },
        },
      };
    },
    selectRow(record) {
      const selectedRowKeys = [...this.selectedRowKeys];
      if (selectedRowKeys.indexOf(record.areaID) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.areaID), 1);
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        if (this.selectedRowKeys.length < 10000) {
          selectedRowKeys.push(record.areaID);
          setTimeout(() => {
            this.setProductsDetails();
          }, 0);
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description: "The maximum number of selected products is 10000.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    selectedEditableProducts(e) {
      if (
        this.selectedRowKeys.length < 10000 ||
        this.selectedRowKeys.length > e.length
      ) {
        this.selectedRowKeys = e;
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        this.$notification["warning"]({
          message: `Oops`,
          description: "The maximum number of selected products is 10000.",
          placement: "bottomLeft",
          duration: 4,
        });
      }
    },
    setProductsDetails() {
      let products = [];
      this.selectedRowKeys.forEach((item) => {
        let product = this.brandProductsList.find(
          (product) => product.areaID === item
        );
        if (product !== undefined) {
          products.push(product);
        }
      });
      this.$emit("products-details", products);
    },
    setBrand(brand) {
      this.chosenBrand = brand;
      this.$emit("brand", brand);
    },
    setBrandDropdown(brand) {
      this.getProducts("click", brand);
    },
    nextStep() {
      this.setProductsDetails();
      this.$emit("nextStep");
    },
    prevStep() {
      this.$emit("prevStep");
    },
  },
  mounted() {},
  created() {
    let imageId = this.activeShelf
      ? this.activeShelf.imageID
      : this.shelfImageId;
    if (imageId !== null) {
      this.getBrandList();
    }

    setTimeout(() => {
      this.loadingFilters = false;
    }, 800);
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.product-list {
  padding-bottom: 70px;
  &__selected {
    background: #fafafa;
    margin-bottom: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  &__image {
    width: 70px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 80px;
    }
  }
  &__table-cta {
    .ant-btn {
      height: 33px;
    }
  }
  &__selected-brand {
    font-size: 15px;
    padding-top: 10px;
    strong {
      font-weight: 500;
    }
    svg {
      color: #646464;
      margin-left: 5px;
      transition: color 0.3s ease;
      &:hover {
        color: #1890ff;
      }
    }
  }
  ::v-deep .custom-table {
    thead {
      border-color: #fff;
      .ant-table-selection {
        display: none;
      }
    }
    tbody {
      tr {
        border-color: #fff;
        td {
          &:nth-child(2) {
            width: 100px;
          }
          &:nth-child(3) {
            min-width: 230px;
          }
          &.ant-table-selection-column {
            > span {
              pointer-events: none;
            }
          }
        }
        &.ant-table-row-selected td {
          background: #e7f4fb !important;
        }
      }
    }
    &.disable-select {
      tr:not(.ant-table-row-selected) {
        .ant-table-selection-column span {
          pointer-events: none;
        }
      }
    }
  }
  .management-system {
    &__tools {
      background: #fafafa;
      padding: 5px 15px 15px 15px;
      border-radius: 4px;
      min-height: 84px;
      position: relative;
      margin-bottom: 25px;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
    &__shelf-info {
      position: absolute;
      bottom: -21px;
      left: 0;
      font-size: 12px;
      margin-bottom: 0;
    }
    &__filters-item {
      > * {
        color: #4c4c4c;
      }
      ::v-deep .ant-select {
        .ant-select-selection {
          &--single {
            border-color: #b0b0b0;
          }
          &__placeholder {
            color: #b0b0b0;
          }
        }
      }
      ::v-deep .ant-input {
        border-color: #b0b0b0;
      }
      &--brand {
        display: none;
      }
      &--brand-show {
        display: block;
      }
      &--search {
        margin-left: 90px;
        position: relative;
        &:after {
          position: absolute;
          left: -50px;
          top: 32px;
          height: 22px;
          width: 1px;
          content: "";
          background: #bababa;
        }
      }
      &--search-first-field {
        // margin-left: 0;
        &:after {
          top: 27px;
        }
        // @media screen and (max-width: 800px) {
        //   width: 100%;
        //   max-width: 100%;
        // }
      }
    }
    &__view-cta {
      margin-top: 10px;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    &__tools-counter {
      @media screen and (max-width: 800px) {
        padding-top: 13px;
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
