<template>
  <div class="progress-bar">
    <template v-if="loading">
      <div
        class="progress-bar__item progress-bar__item--loader"
        v-for="item in 5"
        :key="item"
      >
        <Skeleton :borderRadius="24"></Skeleton>
      </div>
    </template>

    <div
      v-else
      class="progress-bar__item"
      :class="{
        'is-passed': index + 1 < activeStep || editMode,
        'is-active':
          index + 1 ===
          (!activeDeepDiveStep && activeStep === 5 ? 4 : activeStep),
      }"
      v-for="(item, index) in steps"
      :key="item"
      @click="
        index + 1 < activeStep || editMode
          ? $emit('activeStep', index + 1)
          : null
      "
    >
      <i>{{ index + 1 }}</i>
      <span>{{ item }}</span>
      <div class="progress-bar__item-tooltip">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/general/Skeleton.vue";
export default {
  name: "ProgressBar",
  components: {
    Skeleton,
  },
  props: {
    activeStep: {
      type: Number,
    },
    editMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    activeDeepDiveStep: {
      type: Boolean,
    },
    steps: {
      type: Array,
      default() {
        return [
          "Select Shelf",
          "Select Test Products",
          "Upload Test Images",
          "Define Superiority Benchmark",
        ];
      },
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {},
  watch: {
    activeDeepDiveStep(newVal) {
      if (newVal) {
        this.steps.splice(3, 0, "Identify Priority Products");
      } else {
        this.steps.splice(3, 1);
      }
    },
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1200);
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 27px;
  position: relative;
  @media screen and (max-width: 800px) {
    margin-bottom: 12px;
    padding-bottom: 20px;
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 86px;
    border-bottom: 1px solid #d4d4d4;
    content: "";
  }
  &__item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;
    @media screen and (max-width: 800px) {
      margin-right: 6px;
    }
    &--loader {
      width: 36px;
      height: 36px;
    }
    i {
      display: block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      opacity: 0.75;
      background: #d9d9d9;
      @media screen and (max-width: 800px) {
        width: 32px;
        height: 32px;
        font-size: 14px;
      }
    }
    span {
      display: none;
      @media screen and (max-width: 800px) {
        font-size: 14px;
      }
    }
    .skeleton {
      width: 36px;
      height: 36px;
    }
    &.is-passed {
      cursor: pointer;
      i {
        background: #369be1;
        color: #fff;
        opacity: 1;
        font-weight: 500;
      }
    }
    &.is-active {
      i {
        background: #1a3da5;
        color: #fff;
        margin-right: 5px;
        opacity: 1;
        font-weight: 500;
      }
      span {
        display: block;
      }
      .progress-bar__item-tooltip {
        display: none;
      }
    }
    &:hover {
      .progress-bar__item-tooltip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }
  &__item-tooltip {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -3px);
    top: -28px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);
    font-size: 12px;
    border-radius: 5px;
    white-space: nowrap;
    padding: 0 6px;
    opacity: 0;
    transition: 0.3s ease all;
    &:after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -6px;
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      transition: all 0.2s ease;
    }
  }
}
</style>
