var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"competitive-benchmark"},[_c('StimuliTitle',{attrs:{"title":"Please select the relevant competitive product for each test package."}}),_vm._l((_vm.chosenProducts),function(item){return _c('div',{key:item.areaID,staticClass:"flex justify-between"},[_c('div',{staticClass:"competitive-benchmark__item"},[_c('div',{staticClass:"competitive-benchmark__item-inner"},[(item.deepDive)?_c('div',{staticClass:"competitive-benchmark__item-flag"},[_vm._v(" Priority Product ")]):_vm._e(),_c('div',{staticClass:"competitive-benchmark__item-image image-loading"},[_c('img',{attrs:{"src":item.ImageURL},on:{"load":function($event){return $event.target.parentNode.classList.toggle(
                'image-loading--loaded'
              )}}})]),_c('div',{staticClass:"competitive-benchmark__item-info"},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('div',{staticClass:"competitive-benchmark__item-select"},[_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.openCompetitorModal(item)}}},[(
                  _vm.chosenProductsList.hasOwnProperty('product' + item.zoneId)
                )?_c('span',[_vm._v("Change competitor pack")]):_c('span',[_vm._v("Select competitor pack")])])],1)])])]),_c('div',{staticClass:"\n        competitive-benchmark__item\n        competitive-benchmark__item--competitor-pack\n      ",class:{
        'competitive-benchmark__item--dark':
          _vm.chosenProductsList.hasOwnProperty('product' + item.zoneId),
      }},[_c('div',{staticClass:"competitive-benchmark__item-inner"},[(_vm.chosenProductsList.hasOwnProperty('product' + item.zoneId))?_c('div',{staticClass:"competitive-benchmark__item-image"},[_c('img',{attrs:{"src":_vm.chosenProductsList.hasOwnProperty('product' + item.zoneId)
                ? _vm.chosenProductsList['product' + item.zoneId].ImageURL
                : ''}})]):_vm._e(),_c('div',{staticClass:"competitive-benchmark__item-info"},[_vm._v(" "+_vm._s(_vm.chosenProductsList.hasOwnProperty("product" + item.zoneId) ? _vm.chosenProductsList["product" + item.zoneId].productName || _vm.chosenProductsList["product" + item.zoneId].ProductName : "")+" ")]),(_vm.chosenProductsList.hasOwnProperty('product' + item.zoneId))?_c('div',{staticClass:"general-checkbox flex items-center"},[_c('div',{staticClass:"mr-10"},[_c('a-tooltip',{attrs:{"placement":"top","overlayClassName":"ant-tooltip--step-nav"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Adding pack diagnostics for this package this will increase the overall sample size for your study adding incremental costs. ")])]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.chosenProductsList['product' + item.zoneId].CompetitiveDeepDive
            ),expression:"\n              chosenProductsList['product' + item.zoneId].CompetitiveDeepDive\n            "}],attrs:{"type":"checkbox","id":item.zoneId},domProps:{"checked":Array.isArray(
              _vm.chosenProductsList['product' + item.zoneId].CompetitiveDeepDive
            )?_vm._i(
              _vm.chosenProductsList['product' + item.zoneId].CompetitiveDeepDive
            ,null)>-1:(
              _vm.chosenProductsList['product' + item.zoneId].CompetitiveDeepDive
            )},on:{"change":[function($event){var $$a=
              _vm.chosenProductsList['product' + item.zoneId].CompetitiveDeepDive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.chosenProductsList['product' + item.zoneId], "CompetitiveDeepDive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.chosenProductsList['product' + item.zoneId], "CompetitiveDeepDive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.chosenProductsList['product' + item.zoneId], "CompetitiveDeepDive", $$c)}},function($event){_vm.$emit('competitive-products', _vm.chosenProductsList),
                _vm.$emit('enable-deep-dive')}]}}),_c('label',{attrs:{"for":item.zoneId}},[_vm._v("Enable Additional Pack Diagnostics ")])]):_vm._e(),(_vm.chosenProductsList.hasOwnProperty('product' + item.zoneId))?_c('div',{staticClass:"competitive-benchmark__item-delete",on:{"click":function($event){return _vm.deleteChosenProduct(item.zoneId)}}},[_c('a-icon',{attrs:{"type":"close"}})],1):_vm._e(),(!_vm.chosenProductsList.hasOwnProperty('product' + item.zoneId))?_c('p',[_vm._v(" No competitor pack selected. ")]):_vm._e()])])])}),_c('div',{staticClass:"flex justify-between stimuli-nav"},[_c('a-button',{on:{"click":function($event){return _vm.$emit('prevStep')}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),(_vm.activeDeepDiveStep)?_c('span',[_vm._v("Identify "),_c('strong',[_vm._v("Priority")]),_vm._v(" Products")]):_c('span',[_vm._v("Upload Test Images")])],1)],1),_c('a-modal',{attrs:{"visible":_vm.competitorModal,"centered":"","footer":null,"header":false,"wrapClassName":"ant-modal--competitive-benchmark"},on:{"cancel":_vm.closeCompetitorModal}},[_c('p',{staticClass:"into-text"},[_vm._v(" Please select the relevant competitive product for "+_vm._s(_vm.activeProductName)+". ")]),_c('div',{staticClass:"management-system__tools"},[_c('div',{staticClass:"flex items-end management-system__filters"},[_c('div',{staticClass:"management-system__filters-item"},[_c('label',[_vm._v("Brand")]),_c('a-select',{key:Math.random(),staticClass:"fullWidth",attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":_vm.chosenBrand['table' + _vm.activeTableId] || 'All brands'},on:{"change":_vm.setBrand}},[_c('a-select-option',{key:Math.random(),attrs:{"value":"All brands"}},[_vm._v("All brands")]),_vm._l((_vm.brandList),function(item){return _c('a-select-option',{key:item.BrandName,attrs:{"value":item.BrandName,"disabled":_vm.productsDetailsInfo.Brand !== null
                  ? item.BrandName.toLowerCase() ===
                    _vm.productsDetailsInfo.Brand.toLowerCase()
                  : false}},[_vm._v(" "+_vm._s(item.BrandName)+" ")])})],2)],1),(
            _vm.brandProducts.hasOwnProperty('table' + _vm.activeTableId)
              ? _vm.brandProducts['table' + _vm.activeTableId].length > 0
                ? true
                : false
              : false
          )?_c('div',{staticClass:"\n            management-system__filters-item\n            management-system__filters-item--search\n          "},[_c('label',[_vm._v("Search products")]),_c('div',{staticClass:"position-r"},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_c('a-input',{class:{ active: _vm.searchParametars != '' },attrs:{"placeholder":"Insert product name"},model:{value:(_vm.searchParametars),callback:function ($$v) {_vm.searchParametars=$$v},expression:"searchParametars"}},[_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchParametars != ''),expression:"searchParametars != ''"}],attrs:{"slot":"addonAfter","theme":"filled","type":"close-circle"},on:{"click":function($event){_vm.searchParametars = ''}},slot:"addonAfter"})],1)],1)]):_vm._e()])]),_vm._l((_vm.chosenProducts),function(item){return _c('div',{key:item.zoneId,staticClass:"management-system__table",class:{ 'management-system__table--disable': _vm.setAllProductsInfo }},[(
          item.zoneId === _vm.activeTableId &&
          _vm.brandProducts['table' + _vm.activeTableId].length
        )?_c('a-table',{staticClass:"custom-table",class:{
          'disable-select':
            _vm.selectedRowKeys['table' + _vm.activeTableId].length === 1,
          'custom-table--remove-shadow':
            _vm.brandProducts['table' + _vm.activeTableId].length < 4,
        },attrs:{"tableLayout":"auto","columns":_vm.columns,"row-key":function (record) { return record.areaID; },"data-source":_vm.searchProductList,"loading":_vm.loading,"pagination":_vm.pagination,"row-class-name":function (_record) { return (_record.selected ? 'row-selected' : null); },"row-selection":_vm.rowSelection,"custom-row":_vm.customRow,"scroll":{
          y: _vm.brandProducts['table' + _vm.activeTableId].length <= 10 ? 380 : 320,
        }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"Image",fn:function(record){return _c('div',{staticClass:"competitive-benchmark__image image-loading"},[_c('img',{attrs:{"src":record.ImageURL},on:{"load":function($event){return $event.target.parentNode.classList.toggle(
                'image-loading--loaded'
              )}}})])}}],null,true)}):_vm._e(),(_vm.noBrandProducts)?_c('DataInfo',{attrs:{"border0":true,"paddingTop":100,"paddingBottom":100,"text":"\n         There are no products for the selected brand...\n        "}}):_vm._e(),(_vm.loading && item.zoneId === _vm.activeTableId)?_c('Loader',{attrs:{"text":"Loading products...","paddingTop":150}}):_vm._e()],1)}),(_vm.setAllProductsInfo)?_c('div',{staticClass:"competitive-benchmark__copy-product"},[_c('a-icon',{attrs:{"type":"question-circle"}}),_c('span',[_vm._v("Use this competitive benchmark for all test products")]),_c('div',[_c('button',{staticClass:"btn-reset",on:{"click":_vm.setAllProducts}},[_vm._v("Yes")]),_vm._v(" / "),_c('button',{staticClass:"btn-reset",on:{"click":_vm.closeCompetitorModal}},[_vm._v("No")])])],1):_vm._e(),_c('div',{staticClass:"flex justify-end mt-15 competitive-benchmark__modal-cta"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){(_vm.competitorModal = false), (_vm.activeTableSelectedRows = [])}}},[_vm._v("Done ")])],1)],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }